import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';  
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InfoIcon from '@mui/icons-material/Info'; // Icon for showing password requirements
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import "./Register.css";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;


function Register()
{
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [values, setValues] = useState({
    name: '',
    surname: '',
    email: '',
    azienda:'',
    password: '',
    confirmPassword: '',
    showPassword: false,
    error: null,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword    = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setOpen(false);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };


  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,}$/;
    return passwordRegex.test(password);
  }


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (values.password !== values.confirmPassword) {
      setValues({ ...values, error: 'Le password non corrispondono' });
      return;
    }

    if (!validatePassword(values.password)) {
      setValues({ ...values, error: 'La password deve avere almeno 8 caratteri, una lettera maiuscola, un numero e un carattere speciale.' });
      return;
    }


    try {

      const url = '/registrazione';
      
      const response = await axios.post(url, values);

      console.log('Utente registrato:', response.data);

      if(response.status==201)
      {
        setOpen(true); // Mostra lo Snackbar
      }
      
      // Mostra un messaggio di successo all'utente
    } catch (error) {
      console.error('Errore durante la registrazione:', error);
      setValues({ ...values, error: 'Si è verificato un errore durante la registrazione' });
    }
  };


  return (
    <div className='Registrazione'
     style={{
      height: '100vh',
      backgroundImage: 'url(./immagini/login.jpg)', 
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'

    }}>
    <Container maxWidth="sm" sx={{
       height: '100vh',
       display: 'flex',
       justifyContent: 'center',
       alignItems: 'center',
       backgroundColor: 'rgba(0, 0, 0, 0.0)', // Transparent background
    }} >
  
   <Box  sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop:0,
        backgroundColor: 'rgba(0, 0, 0, 0.1)', // Transparent background
        }}
        >
    <form onSubmit={handleSubmit}>
      <TextField
        label="Nome"
        type = "name"
        variant="outlined"
        margin="normal"
        value={values.name}
        onChange={handleChange('name')}
        fullWidth
        required
        sx={{ 
          borderRadius: 3,
          bgcolor: 'background.paper',
          width: '100%', // Mantieni la stessa larghezza
          minWidth: '300px' // Stessa larghezza minima
          
       }} // Colore di sfondo del TextField
      />

      <TextField
        label="Cognome"
        type ="surname"
        variant="outlined"
        margin="normal"
        value={values.surname}
        onChange={handleChange('surname')}
        fullWidth
        required
        sx={{ 
          borderRadius: 3,
          bgcolor: 'background.paper',
          width: '100%', // Mantieni la stessa larghezza
          minWidth: '300px' // Stessa larghezza minima
          
       }} // Colore di sfondo del TextField
      />

      <TextField
        label="Email"
        type="email"
        variant="outlined"
        margin="normal"
        onChange={handleChange('email')}
        fullWidth
        required
        sx={{ 
          borderRadius: 3,
          bgcolor: 'background.paper',
          width: '100%', // Mantieni la stessa larghezza
          minWidth: '300px' // Stessa larghezza minima
          
       }} // Colore di sfondo del TextField
      />
       <TextField
        label="Azienda"
        type="azienda"
        variant="outlined"
        margin="normal"
        value={values.azienda}
        onChange={handleChange('azienda')}
        fullWidth
        required
        sx={{ 
          borderRadius: 3,
          bgcolor: 'background.paper',
          width: '100%', // Mantieni la stessa larghezza
          minWidth: '300px' // Stessa larghezza minima
          
       }} // Colore di sfondo del TextField
      />



      <TextField
       label="Password"
       type={values.showPassword ? 'text' : 'password'}
       variant="outlined"
       margin="normal"
       fullWidth
       required
       value={values.password}
       onChange={handleChange('password')}
       sx={{ 
        borderRadius: 3,
        bgcolor: 'background.paper',
        width: '100%', // Mantieni la stessa larghezza
        minWidth: '300px' // Stessa larghezza minima
        
        }} // Colore di sfondo del TextField

        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"  
                onClick={handleClickShowPassword}  
                edge="end"
              >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
              <Tooltip title="Mostra requisiti password">
                    <IconButton onClick={() => setDialogOpen(true)}>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
            </InputAdornment>
          ),
        }}


      />

        
        <TextField
         label="Ripeti Password"
         type={values.showPassword ? 'text' : 'password'}
         variant="outlined"
         margin="normal"
         fullWidth
         required
         value={values.confirmPassword}
         onChange={handleChange('confirmPassword')}
         sx={{ 
          borderRadius: 3,
          bgcolor: 'background.paper',
          width: '100%', // Mantieni la stessa larghezza
          minWidth: '300px' // Stessa larghezza minima
          
       }} // Colore di sfondo del TextField
         InputProps={{
           endAdornment: (
             <InputAdornment position="end">
               <IconButton
                 aria-label="toggle password visibility"
                 onClick={handleClickShowPassword}
                 edge="end"
               >
                 {values.showPassword ? <VisibilityOff /> : <Visibility />}
               </IconButton>
             </InputAdornment>   

           ),
         }}
      />
      <Button
        type="submit"
        variant="contained"   
        color="primary"
        fullWidth   
        onClick={handleSubmit}
        sx={{
        marginTop : 3,
        borderRadius:5,
        width: 'auto', // Imposta la larghezza automatica basata sul contenuto
        }}

      >
        Registrati
      </Button>
    </form>

    {values.error && (
        <Alert severity="error">
          <AlertTitle>Errore</AlertTitle>
          {values.error}
        </Alert>
      )}

    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
    <Alert severity="success" sx={{ width: '100%' }}>
      Registrazione avvenuta con successo!
    </Alert>
  </Snackbar>
  <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Requisiti Password</DialogTitle>
          <DialogContent>
            <ul>
              <li>Almeno 8 caratteri</li>
              <li>Almeno una lettera maiuscola</li>
              <li>Almeno un numero</li>
              <li>Almeno un carattere speciale (!@#$%^&*)</li>
            </ul>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Chiudi
            </Button>
          </DialogActions>
        </Dialog>
        
    </Box>

    </Container>
    </div>
  );
  }
export default Register;