import React from 'react';
import "./home.css"
const Home = () => {
 
  return (
   <div className='Container'>
      <h1>Welcome to the Home Page!</h1>
      <p>Campagnuolo & Associati Srl is a company
inspired by the principles of sustainable
development that leverages technological and
organizational innovation and the extraordinary
human and professional heritage at its disposal
in order to develop constructive solutions.</p>

  </div>
  );
}

export default Home;