import React from 'react';

function SezioneGenerale({ handleChange, dati }) 
{
  return (
    <div>
      <h2>Sezione Generale</h2>  
      <input type="text" name="Effetuato Da:" value={dati.effettuatoDa} onChange={handleChange}/>
      <input type="text" name="Società:" value={dati.Societa} onChange={handleChange}/>
      <input type="text" name="Descrizione Lavori:" value={dati.descrizioneLavori} onChange={handleChange}/>
    </div>
  );
}

export default SezioneGenerale;