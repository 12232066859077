import React, { useState } from 'react';
//import DatePicker from 'react-datepicker';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

 function DettagliIspezione({}) {
  const [inspectionDate, setInspectionDate] = useState(new Date());
  const [inspectionStatus, setInspectionStatus] = useState('Pianificata'); // Opzioni: Pianificata, In Corso, Completata
  
  const [notes, setNotes] = useState('');
  const [files, setFiles] = useState([]);
 
  const handleDateChangeLocal = (date) => {
    setInspectionDate(date);
    //onDateChange(date);
  };

  const handleStatusChange = (event) => {
    setInspectionStatus(event.target.value);
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  const handleFileChange = (event) => {
    setFiles([...files, event.target.files[0]]);
  };

  const gestioneProgrammaVisita =()=>
  {
    alert("stai Programmando una visita Ispettiva");

    //salvare tutto sul data base
  }

  return (
    <div className="inspection-details">
      <h2>Dettagli dell'Ispezione</h2>
     
      <div className="form-group">
      <h2>Stato Ispezione:</h2>
        <label htmlFor="status">Stato:</label>
        <select id="status" value={inspectionStatus} onChange={handleStatusChange}>
          <option value="Pianificata">Pianificata</option>
          <option value="In Corso">In Corso</option>
          <option value="Completata">Completata</option>
        </select>
      </div>
      <div className="form-group">
        <h2>Lavori:</h2>
        <label htmlFor="notes">Note:</label>
        <textarea id="notes" value={notes} onChange={handleNotesChange} />
      </div>
      <div className="form-group">
        <label htmlFor="files">Allegati:</label>
        <input type="file" id="files" multiple onChange={handleFileChange} />
        <ul>
          {files.map((file, index) => (
            <li key={index}>{file.name}</li>
          ))}
        </ul>
       
      </div>
      <div className="site-info">
        <h3>Cantiere</h3>
        <p>Indirizzo: {}</p>
        <p>Contatto: {}</p>
      </div>
      <div className= "ruoli">
              
      </div>
      
      

    </div>
  );
}

export default DettagliIspezione;