import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Typography, Card, CardContent, Grid, AppBar, Toolbar, Button } from '@mui/material';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import axios from 'axios';
import Barra from './Barra';
import dayjs from 'dayjs';
import StatoCantieriCard from './StatoCantiereCard';
import { useCantiere } from './CantiereContext';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const InformazioniEsteseSito = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { site } = location.state || {};
  const { setInfo } = useCantiere();
  const [isBarraOpen, setIsBarraOpen] = useState(false);
  const [cantieri, setCantieri] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCantieri = async () => {
      try {
        console.log("sito:",site.ID)
        const response = await axios.get('/ricercaCantiere', { params: { sitoID: site.ID } });
        if (Array.isArray(response.data)) {
          setCantieri(response.data);
        } else {
          setCantieri([]);
         
        }
      } catch (error) {
        
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (site) {
      fetchCantieri();
    }
  }, [site]);

  const calcolaCantieri = (condizione) => {
    if (Array.isArray(cantieri)) {
      return cantieri.filter(condizione).length;
    }
    return 0; // Valore di fallback
  };

  const cantieriAttivi = calcolaCantieri(cantiere => dayjs(cantiere.dataFine, 'DD/MM/YYYY').isAfter(dayjs()));
  const cantieriCompletati = calcolaCantieri(cantiere => dayjs(cantiere.dataFine, 'DD/MM/YYYY').isBefore(dayjs()));
  const cantieriInProgramma = calcolaCantieri(cantiere => dayjs(cantiere.dataInizio, 'DD/MM/YYYY').isAfter(dayjs()));

  const creaNuovoCantiere = () => {
    navigate("/creaCantiere", { state: { sito: site, ID: site.ID } });
  };

  const visitaCantiere = (data) => {
    setInfo(JSON.stringify(data));
    navigate("/gestioneCantiere");
  };

  const toggleBarra = () => setIsBarraOpen(prev => !prev);

  if (loading) {
    return <Typography variant="h6">Caricamento in corso...</Typography>;
  }

  if (error) {
    return <Typography variant="h6" color="error">{error}</Typography>;
  }

  if (!site) {
    return <Typography variant="h6">Nessun sito trovato</Typography>;
  }

  return (
    <div style={{ backgroundColor: '#39414C' }}>
      <Barra isBarraOpen={isBarraOpen} toggleBarra={toggleBarra} />
      <AppBar position="static">
        <Toolbar sx={{ backgroundColor: '#A0C646' }}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            LGS - Informazioni Sito
          </Typography>
          <Button color="primary" variant="contained" sx={{ ml: 2 }} onClick={creaNuovoCantiere}>
            Crea un Nuovo Cantiere
          </Button>
          <Button color="primary" variant="contained" sx={{ ml: 2 }} onClick={toggleBarra}>
            <AddCircleOutline />
          </Button>
        </Toolbar>
      </AppBar>
      <Container sx={{ backgroundColor: '#39414C' }}>
        <Grid container spacing={3}>
          {/* Informazioni Generali */}
          <Grid item xs={12} md={6}>
            <Card sx={{ backgroundColor: '#FFCCCC', border: '2px solid red' }}>
              <CardContent>
                <Typography variant="h6">Informazioni Generali:</Typography>
                <Typography variant="body1">Cluster: {site.Cluster}</Typography>
                <Typography variant="body1">Nazione: {site.nazione}</Typography>
                <Typography variant="body1">Città: {site.citta}</Typography>
                <Typography variant="body1">Indirizzo: {site.indirizzo}</Typography>
              </CardContent>
            </Card>
          </Grid>
          {/* Project Manager e Ticket */}
          <Grid item xs={12} md={3}>
            <Card sx={{ backgroundColor: '#d4edda', border: '2px solid red' }}>
              <CardContent>
                <Typography variant="h6">Project Manager:</Typography>
                <Typography variant="body1">{site.projectManagerNome} {site.projectManagerCognome}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card sx={{ backgroundColor: '#d4edda', border: '2px solid red' }}>
              <CardContent>
                <Typography variant="h6">Ticket</Typography>
                <Typography variant="body1">Ticket Aperti: 30</Typography>
                <Typography variant="body1">Ticket Evasi: 121</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* Stato Cantieri */}
        <Grid item xs={12} sx={{ marginTop: 2, backgroundColor: '#F5F5DC', border: '2px solid red' }}>
          <StatoCantieriCard
            cantieriAttivi={cantieriAttivi}
            cantieriCompletati={cantieriCompletati}
            cantieriInProgramma={cantieriInProgramma}
            width={300}
            height={300}
            outerRadius={60}
          />
        </Grid>
        {/* Elenco Cantieri */}
        <Grid item xs={12} sx={{ marginTop: 2, backgroundColor: '#F5F5DC' }}>
          <div style={{ maxHeight: '400px', overflowY: 'scroll', border: '2px solid red' }}>
            <Typography variant="h6">Elenco Cantieri:</Typography>
            {cantieri.length > 0 ? (
              cantieri.map((cantiere) => (
                <Grid item xs={12} key={cantiere.ID} sx={{ marginTop: 2 }}>
                  <Card sx={{ backgroundColor: '#f8f9fa' }}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={5}>
                          <Typography variant="h6">{cantiere.nome}</Typography>
                          <Typography variant="body1">Stato: {cantiere.stato}</Typography>
                          <Typography variant="body1">ODA: {cantiere.ordine_acquisto}</Typography>
                          <Typography variant="body1">Importo dei Lavori: {cantiere.import_lavori ? cantiere.import_lavori.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) : 'Non disponibile'}</Typography>
                          <Typography variant="body1">Descrizione: {cantiere.descrizione}</Typography>
                          <Typography variant="body1">Data Inizio: {cantiere.dataInizio}</Typography>
                          {/* Aggiungi ulteriori dettagli se necessario */}
                        </Grid>
                        <Grid item xs={7}>
                          {/* Informazioni aggiuntive sul cantiere */}
                          <Button variant="contained" color="primary" onClick={() => visitaCantiere(cantiere)}>Gestisci Cantiere</Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              <Typography variant="body1">Nessun cantiere trovato per questo sito.</Typography>
            )}
          </div>
        </Grid>
      </Container>
    </div>
  );
};

export default InformazioniEsteseSito;