import React, { useState,useEffect } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Button } from '@mui/material'; // Assicurati di importare i componenti necessari
import DataRow from './DataRow';
import { useCantiere } from './CantiereContext';
import axios from 'axios';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;


function VisitaIspettiva() {
  
  
const [rowsData, setRowsData] = useState([]);

  let cantiere={};
  const {info} = useCantiere();

 if (typeof info === 'string') {
  cantiere = JSON.parse(info);
} else {
  console.error("info non è una stringa JSON valida:", info);
  cantiere = info; // Supponendo che info sia già un oggetto
}

  const handleSalvaDati = async () => {
    try {
      
      const response = await axios.post("/aggiornaDocumenti", { data: rowsData, IDcantiere:cantiere.ID });
     
      
      // Puoi anche fare qualcosa dopo che i dati sono stati salvati con successo, come mostrare un messaggio di successo
    } catch (error) {
      console.log('Errore durante il salvataggio dei dati:', error);
      // Gestisci l'errore come desideri, ad esempio mostrare un messaggio di errore all'utente
    }
  };

  const handleSelect = (id) => {
    // Implementa la logica per selezionare i dati qui
    setRowsData((prev) => [...prev, id]);
  };


 
const handleDataChange = (id, newData) => {

  // Aggiorna lo stato della riga corrispondente
  setRowsData((prevRows) =>
    prevRows.map((row) =>
      row.id === id ? { ...row, ...newData } : row
    )
  );
};



  useEffect(() => {

    console.log("useEffect attivato con cantiere.ID:", cantiere.ID);

    if (!cantiere.ID) return;
  
    const fetchData = async () => {
      try {
       const response = await axios.get(`/getDocumenti/${cantiere.ID}`);
       
       if (response.status===201)
       {

        setRowsData([
          { id: 1, text: 'Nomina RLP', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 2, text: 'Nomina RLE', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 3, text: 'Nomina CSP', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 4, text: 'Nomina CSE', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 5, text: 'Verbale consegna lavori', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 6, text: 'Verbale di coordinamento con RSPP di sito', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 7, text: 'PSC di progetto', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 8, text: 'Fascicolo opera di progetto', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 9, text: 'PSC Ultimo Aggiornamento', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 10, text: 'POS impresa affidataria', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 11, text: 'POS impresa affidataria Ultimo Aggiornamento', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 12, text: 'Notifica preliminare', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 13, text: 'Notifica preliminare Ultimo Aggiornamento', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 14, text: 'Formazione Lavoratori Impresa Aff.', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 15, text: 'UNILAV Lavoratori Impresa Aff.', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 16, text: 'POS imprese esecutrici', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 17, text: 'POS imprese esecutrici Ultimo Aggiornamento', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 18, text: 'Formazione Lavoratori Impresa esec. presenti in cantiere alla data della visita', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 19, text: 'UNILAV Lavoratori Impresa esec. presenti in cantiere alla data della visita', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 20, text: 'Autorizzazione subappalti', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 21, text: 'Contratti di subappalto imprese', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 22, text: 'Documentazione per la Verifica di Idoneità Tecnico Professionale impresa Affidataria', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 23, text: 'Documentazione per la Verifica di Idoneità Tecnico Professionale imprese Esecutrici', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 24, text: 'Nomina Preposto Impresa Aff.', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 25, text: 'Nomina Preposti Impresa Esec.', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 26, text: 'Verbali di sopralluogo emessi dal CSE', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null },
          { id: 27, text: 'Verbale che attesti ingresso in cantiere della impresa subappaltatrice', isCheckedSI: false, isCheckedNO: false, isCheckedNA: false, note: '', file: null }

        ]);
            return;

       }
      
     
        
        if (response.data && response.data.length > 0) {
          
        
          const mappedData = response.data.map((doc) => ({
            
            

            id: doc.id || 0,
            text: doc.tipodoc || '',  // Assumi che `tipodoc` contenga il testo del documento
            isCheckedSI: Boolean(doc.statosi), // Corretto a `statosi` e convertito a booleano
            isCheckedNO: Boolean(doc.statono), // Convertito a booleano
            isCheckedNA: Boolean(doc.stato_no),  // Convertito a booleano
            note:doc.note,
            file: doc.file || null
          
          })
          
          
          );
         
          
          setRowsData(mappedData);
        }
      }
       catch (error) {
        console.error('Errore durante il recupero dei dati dal database:', error);
      
      }
    };   
    
    fetchData();

  }, [cantiere.ID]);


  return (
    <>
     <div style={{ 
       maxHeight: '400px', // Imposta un'altezza massima per il contenuto
       border: '2px solid black',
      overflowY: 'auto', // Nasconde la scrollbar verticale
      }}>
      <Table sx={{ borderCollapse: 'separate',  borderSpacing: 0, }}>
        <TableHead sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
          <TableRow>
            <TableCell sx={{ border: '2px solid black' }}>Documento</TableCell>
            <TableCell sx={{ border: '2px solid black' }}>SI</TableCell>
            <TableCell sx={{ border: '2px solid black' }}>NO</TableCell>
            <TableCell sx={{ border: '2px solid black' }}>NA</TableCell>
            <TableCell sx={{ border: '2px solid black' }}>Note</TableCell>
            <TableCell sx={{ border: '2px solid black' }}>File</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsData.map((row1) => {
         
            return(<DataRow
            key={row1.id}
            onDataChange={handleDataChange}
            row={row1}
            />
            );
            })}
        </TableBody>
      </Table>
      </div>
      <Button onClick={handleSalvaDati}>Salva Dati</Button>
    </>
  );
}

export default VisitaIspettiva;