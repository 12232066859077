import React from 'react';
import  './VerificaGestioneAttivita.css'

function VerificaGestioneAttivita({ handleChange, dati }) {
  return (
    <div>
      <h2>Verifica gestione attività</h2>
      {/* Documentazione e pianificazione */}
      <label>DUVRI:</label>
      <select name="duvri" value={dati.duvri} onChange={handleChange}>
        <option value="conforme">Conforme</option>
        <option value="nonConforme">Non conforme</option>
        <option value="nonPresente">Non presente</option>
      </select>
      <label>
        Titolo IV Unica Imprese Esecutrice
      </label>
      <input type="checkbox"  onChange={handleChange} />
    
      <label>
        Titolo IV (PSC del 06/2023)
      </label>
      <input type="checkbox" onChange={handleChange} />
      <label>
       Verbale di consegna dei lavori
      </label>
      <input type="checkbox"  onChange={handleChange} />
      <label>
       Note:
      </label>
      <input type="text" onChange={handleChange} /> 
      <label>
       Verbale di cordinamenento con RSPP
      </label>
      <input type="checkbox"  onChange={handleChange} />
      <label>
       Note:
      </label>
      <input type="text"  onChange={handleChange} /> 
     <p>Interferenze:</p>
     <label>
        Presenza altre attività/cantieri contemporanei:
      </label>
     <input type="checkbox" onChange={handleChange} /> 
     <label>
        Gestione delle interferenze:
      </label>
     <input type="checkbox" onChange={handleChange} /> 
     <label>
        Altre interferenze:
      </label>
     <input type="checkbox"  onChange={handleChange} /> 
     <label>
       Note:
      </label>
      <input type="text" onChange={handleChange} /> 
    
    
    </div>

  );
}

export default VerificaGestioneAttivita;