import React, { useState, useEffect } from 'react';
import { TableRow, TableCell, Checkbox, TextField, Button, LinearProgress,Box,Typography } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useCantiere } from './CantiereContext';
import axios from 'axios'; 
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

function DataRow({ onDataChange, row }) 
{
  const [isCheckedSI, setIsCheckedSI] = useState(row.isCheckedSI);
  const [isCheckedNO, setIsCheckedNO] = useState(row.isCheckedNO);
  const [isCheckedNA, setIsCheckedNA] = useState(row.isCheckedNA);
  const [note, setNote] = useState(row.note || '');
  const [file, setFile] = useState(row.file || null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const { info } = useCantiere();
  let cantiere = {};

  if (typeof info === 'string') {
    cantiere = JSON.parse(info);
  } else {
    console.error("info non è una stringa JSON valida:", info);
    cantiere = info; // Supponendo che info sia già un oggetto
  }

  useEffect(() => {

  

    onDataChange(row.id, {
      isCheckedSI,
      isCheckedNO,
      isCheckedNA,
      note,
      file,
    });
  }, [isCheckedSI, isCheckedNO, isCheckedNA, note, file, onDataChange, row.id]);

  const handleCheckboxChange = (type) => {
    setIsCheckedSI(type === 'SI');
    setIsCheckedNO(type === 'NO');
    setIsCheckedNA(type === 'NA');
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setFile(file);
    console.log(cantiere);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('IDcantiere', cantiere.ID);

    try {

      const uploadUrl = `/upload/${cantiere.sitoId}/${cantiere.ID}`;
      const response = await axios.post(uploadUrl, formData, {
       
          onUploadProgress: (progressEvent) => {
          setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        },
      });
      
    } catch (error) {
      console.error("Errore durante l'upload del file:", error);
    }
  };

  return (
    <TableRow sx={ {border: '1px solid black'}}>
      <TableCell sx={ {border: '1px solid black'}}>{row.text}</TableCell>
      <TableCell sx={ {border: '1px solid black'}}>
        <Checkbox checked={isCheckedSI} onChange={() => handleCheckboxChange('SI')} />
      </TableCell>
      <TableCell sx={ {border: '1px solid black'}}>
        <Checkbox checked={isCheckedNO} onChange={() => handleCheckboxChange('NO')} />
      </TableCell>
      <TableCell sx={ {border: '1px solid black'}}>
        <Checkbox checked={isCheckedNA} onChange={() => handleCheckboxChange('NA')} />
      </TableCell>
      <TableCell sx={ {border: '1px solid black'}}>
        <TextField value={note} onChange={handleNoteChange} />
      </TableCell>
      <TableCell sx={ {border: '1px solid black'}}>
        <Button variant="contained" component="label" startIcon={<UploadFileIcon />}>
          Carica File
          <input type="file" hidden onChange={handleFileChange} />
        </Button>
        {uploadProgress > 0 &&  <Box sx={{ marginTop: 2 }}> 
            <LinearProgress variant="determinate" value={uploadProgress}   sx={{ height: 10, backgroundColor: '#e0e0e0', '& .MuiLinearProgress-bar': { backgroundColor: 'green' } }}/>
            <Typography variant="caption" sx={{ display: 'block', textAlign: 'center', marginTop: 0.5 }}>
              Caricamento: {uploadProgress}%
            </Typography>
            
            {file && (
          <Typography variant="body2" sx={{ textAlign: 'center', marginTop: 1 }}>
          File caricato: {file.name}
        </Typography>
        )}
          </Box>}
      </TableCell>
    </TableRow>
  );
}

export default DataRow;