import React, { useState } from 'react';
import { Grid, Card, CardMedia,CardHeader ,CardContent, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import image1 from '../immagini/Pasquale_Cuccaro.png';
import image2 from '../immagini/Pasquale_Cuccaro1.png';
import image3 from '../immagini/Pasquale_Cuccaro1.png';
import image4 from '../immagini/Pasquale_Cuccaro1.png';
import image5 from '../immagini/Pasquale_Cuccaro1.png';
import image6 from '../immagini/Pasquale_Cuccaro1.png';
import image7 from '../immagini/Pasquale_Cuccaro1.png';
import image8 from '../immagini/Pasquale_Cuccaro1.png';
import image9 from '../immagini/Pasquale_Cuccaro1.png';
import image10 from '../immagini/Pasquale_Cuccaro1.png';
import image11 from '../immagini/Pasquale_Cuccaro1.png';
import image12 from '../immagini/Pasquale_Cuccaro1.png';
import image13 from '../immagini/Pasquale_Cuccaro1.png';
import image14 from '../immagini/Pasquale_Cuccaro1.png';
import image15 from '../immagini/Pasquale_Cuccaro1.png';
import image16 from '../immagini/Pasquale_Cuccaro1.png';
const styles = {
    text: {
        textAlign: 'justify',
    },
};

const TeamPage = () => {
    const [selectedEmployee, setSelectedEmployee] = useState(null);

    const employees = [
        { id: 1, 
          name: 'Fulvio Campagnuolo',
          ruolo:'Founder & Parthner',
          bio: 'was born in Naples on February 17, 1964, in 1982 he graduated from the “Nunziatella Military School” and obtained military degrees and honorable numbers during his studies. In 1991 he graduated in Civil Construction Engineering at the Federico II University of Naples. Since 1994, he has been involved in Safety in the Workplace. As an expert and certified trainer, he has held and organized national level training courses, conferences, and seminars. Father of two sons Alessia and Mario, he lives between Caserta, Milan, and Doha where he lives and is CEO of a two company under Qatari law.',
          experience:'His professional experiences began in 1987 as founder and partner of the Engineering Company SIGLA S.r.l. - Works Management Engineering Company, registered with the OICE (Association of Engineering Organizations), with which it follows, as coordinator,construction sites of particular importance, including the ENEL skyscrapers of the Cento Direzionale di Napoli, the Authority Mostra d’Oltremare, the phase of completion of the Piscinola and Marianella ex-earthquake reconstruction sector. In 1993, on behalf of SABESA Spa, he took on the role of construction site manager of the COPIN Consortium, a consortium established for the construction of the “Intermodal Park of the Flegrea area” (archeology, bridges, infrastructures, roads, , reclamation and demining of military installations). In 1996, together with his brother Ennio, he established the Campagnuolo & Associati Engineering Company, which is now fully operational, expert and certified in the management of safety in the workplace. It boasts dozens of customers among Local Authorities, ASL, Provincial Administrations, the Royal Palace of Caserta, the Department of Studies of Caserta and that of Avellino, INAIL Campania, and large companies such as GORI Spa (ACEA group), the Alto Calore Water Consortium of Avellino, ASUB Spa and SIS Spa (Service Company of the Provincial Administration of Naples), Terra di Lavoro SpA (Service Company of the Provincial Administration of Caserta). Over the years, the same company has also gained significant experience in the management of particularly complex works such as, the CUS (University Sports Center) of Naples, the “Judicial Citadel of Salerno”, the construction of military bases in Qatar on behalf of Leonardo Spa.',
          image: image1 },

        { id: 2, 
          name: 'Ennio Campagnuolo', 
          ruolo:'Founder & Parthner', 
          bio: 'Biography of Employee 2', 
          image: image2 },

        { id: 3, name: 'Alessia Campagnuolo', ruolo:'Founder & Parthner', bio: 'Biography of Employee 2', image: 'employee2.jpg' },
        
        { id: 4, name: 'Fabio Campagnuolo', ruolo:'Founder & Parthner', bio: 'Biography of Employee 2', image: 'employee2.jpg' },
        
        { id: 5, name: 'Ferdinando Campagnuolo',ruolo:'Founder & Parthner',  bio: 'Biography of Employee 2', image: 'employee2.jpg' },
        
        {id: 6,  name: 'Gaia Campagnuolo',ruolo:'Founder & Parthner',  bio: 'Biography of Employee 2', image: 'employee2.jpg' },
        
        {id:7,   name: 'Pasquale Crisci', ruolo:'Founder & Parthner', bio: 'Biography of Employee 2', image: 'employee2.jpg' },
        
        {id: 8,  name: 'Pasquale Cuccaro',ruolo:'Founder & Parthner',  bio: 'Biography of Employee 2', image: 'Pasquaele_Cuccaro.jpg' },
        
        {id: 9,  name: 'Sossio Del Prete',ruolo:'Founder & Parthner',  bio: 'Biography of Employee 2', image: 'Pasquaele_Cuccaro.jpg' },
        
        {id: 10, name: 'Gennaro Di Lauro', ruolo:'Founder & Parthner', bio: 'Biography of Employee 2', image: 'Pasquaele_Cuccaro.jpg' },
        
        {id: 11, name: 'Antonietta Feola', ruolo:'Founder & Parthner', bio: 'Biography of Employee 2', image: 'Pasquaele_Cuccaro.jpg' },
        
        {id: 12, name: 'Gianfranco Leazza',ruolo:'Founder & Parthner',  bio: 'Biography of Employee 2', image: 'Pasquaele_Cuccaro.jpg' },
        
        {id: 13, name: 'Giuseppe Monzo',ruolo:'Founder & Parthner',  bio: 'Biography of Employee 2', image: 'Pasquaele_Cuccaro.jpg' },
        
        {id: 14, name: 'Federica Pedà',ruolo:'Founder & Parthner',  bio: 'Biography of Employee 2', image: 'Pasquaele_Cuccaro.jpg' },
        
        {id: 15, name: 'Paolo Sacco',ruolo:'Founder & Parthner',  bio: 'Biography of Employee 2', image: 'Pasquaele_Cuccaro.jpg' },
        
        {id: 16, name: 'Maria Seguino', ruolo:'Founder & Parthner', bio: 'Biography of Employee 2', image: 'Pasquaele_Cuccaro.jpg' },
    ];
    const handleEmployeeClick = (employee) => {
        setSelectedEmployee(employee);
    };

    const handleCloseDialog = () => {
        setSelectedEmployee(null);
    };

    return (
        <Grid container spacing={3}>
            {employees.map(employee => (
            <Grid item xs={4} key={employee.id}>
             <Card>
             <CardHeader
       
                  title={employee.name}
      
               />
                <CardMedia
                    component="img"
                    alt={employee.name} // Testo alternativo per l'immagine
                    height="800" // Imposta l'altezza dell'immagine a 500px
                    width="800" // Imposta la larghezza dell'immagine a 120px
                    image={employee.image}
                    title={employee.ruolo} // Suggerimento quando si passa sopra l'immagine
                    style={{ position: 'relative', objectFit: 'cover' }} // Stile per posizionamento relativo
                >
                   
                    </CardMedia>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                {employee.name}
                            </Typography>
                            <Button variant="outlined" onClick={() => handleEmployeeClick(employee)}>View Bio</Button>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
            <Dialog open={selectedEmployee !== null} onClose={handleCloseDialog}>
                <DialogTitle>{selectedEmployee?.name}</DialogTitle>
                <DialogContent>
                    <Typography style={styles.text}><strong>Biography:</strong> {selectedEmployee?.bio}</Typography>
                    <Typography style={styles.text}><strong>Experience:</strong> {selectedEmployee?.experience}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}



export default TeamPage;