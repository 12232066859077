import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import axios from 'axios';
import { useCantiere } from './CantiereContext';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
let data = {};
const LeggiImpresa = () => {
  const [imprese, setImprese] = useState([]);
  const { info } = useCantiere(); // Ottieni i dati dell'utente

  if (typeof info === 'string') {
    data = JSON.parse(info);
} else {
    console.error("info non è una stringa JSON valida:", info);
    data = info; // Supponendo che info sia già un oggetto
}

  useEffect(() => {
    // Effettua una chiamata API al backend per ottenere i dati
    const url = "/ricercaImpresaCantiere";
    // Controlla se data.ID è definito prima di fare la richiesta
    if (data.ID) {
      axios.post(url, { idCantiere: data.ID }) // Usa POST per inviare il corpo
        .then((response) => {
          setImprese(response.data); // Imposta le imprese ricevute
        })
        .catch((error) => {
          console.error('Errore nel recupero dei dati:', error);
        });
    } else {
      console.log('ID cantiere non valido:', data.ID);
    }
  }, []); 

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Tipo</TableCell>
            <TableCell>Ragione Sociale</TableCell>
            <TableCell>Indirizzo Sede Legale</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Telefono</TableCell>
            <TableCell>P.E.C.</TableCell>
            <TableCell>Tipo Lavorazione</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {imprese.map((impresa) => (
            <TableRow key={impresa.ID}>
              <TableCell>{impresa.tipo}</TableCell>
              <TableCell>{impresa.RagioneSociale}</TableCell>
              <TableCell>{impresa.indirizzosedelegale}</TableCell>
              <TableCell>{impresa.email}</TableCell>
              <TableCell>{impresa.telefono}</TableCell>
              <TableCell>{impresa.postacertificata}</TableCell>
             <TableCell>{impresa.TipoLavorazione}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LeggiImpresa;