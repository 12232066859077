import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Grid ,Button} from '@mui/material';
import  Map  from './Map';
import Barra from './Barra';
import { AddCircleOutline } from '@mui/icons-material';
import { Card, CardContent, CardMedia } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import { Bar, Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip as ChartTooltip, Legend, CategoryScale,  registerables } from 'chart.js';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { Divider } from '@mui/material';
Chart.register(...registerables);

const SitiChart = ({ CantieriAttivi, CantieriCompletati }) => {
  const data = {
    labels: ['Cantieri Attivi', 'Cantieri Completati'],
    datasets: [
      {
        label: 'Stato Cantieri',
        data: [CantieriAttivi, CantieriCompletati],
        backgroundColor: ['#FFA500', '#0000FF'], // Arancione e blu
        hoverBackgroundColor: ['#FFB347', '#1E90FF'], // Colori più chiari per l'hover
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || '';
            const value = context.raw;
            return `${label}: ${value}`;
          },
        },
      },
      title: {
        display: true, // Abilita la visualizzazione del titolo
        text: 'Informazioni sullo Stato dei Cantieri', // Inserisci il testo del titolo
        font: {
          size: 18, // Dimensione del font del titolo
        },
        },
    },
  };
  return (
    <div style={{ width: '100%', height: '400px' }}>
      
      <Doughnut data={data} options={options} />
      
    </div>
  );
};

const EvasioneTicket = ({ ticketEvasi, TicketAttivi }) => {
  const data = {
    labels: ['Tickets Attivi', 'Tickets Evasi'],
    datasets: [
      {
        label: 'Stato Ticket',
        data: [TicketAttivi, ticketEvasi],
        backgroundColor: ['#8A2BE2', '#FFD700'], // Viola e giallo dorato
        hoverBackgroundColor: ['#9B30FF', '#FFEC8B'], // Viola più chiaro e giallo più chiaro
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || '';
            const value = context.raw;
            return `${label}: ${value}`;
          },
        },
      },
      title: {
        display: true, // Abilita la visualizzazione del titolo
        text: 'Informazioni stato dei Ticket', // Inserisci il testo del titolo
        font: {
          size: 18, // Dimensione del font del titolo
        },
        },
    },
  };
  return (
    <div style={{ width: '100%', height: '400px' }}>
      
      <Doughnut data={data} options={options} />
      
    </div>
  );
};



function DashBoard() {
  const [data, setData] = useState([]);
  const [isBarraOpen, setIsBarraOpen] = useState(false);

  const toggleBarra = () => {
    setIsBarraOpen(!isBarraOpen); // Updated to toggle the sidebar state based on the passed argument
  };
 
  const gestioneBARRA=()=>{
    console.log("pulsante premuto");
   const a = true;
    setIsBarraOpen(a);
  }

  useEffect(() => {
    // This code will run after the component is rendered for the first time
    // and whenever the count state changes
    setIsBarraOpen(false);
  }, []);

  return (
    <div  style={{ backgroundColor: '#39414C'}}>
      <Barra isBarraOpen={isBarraOpen} toggleBarra={toggleBarra}/>
      <AppBar position="static">
        <Toolbar sx={{backgroundColor: '#A0C646;'}}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Gestione Cantieri LGS
           
          </Typography>
          <Button color="primary" variant="contained" sx={{ ml: 2 }} onClick={gestioneBARRA}>
          <AddCircleOutline />  
          </Button>
        </Toolbar>
      </AppBar>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Map/>
        </Grid>
        <Grid item xs={12} sm={6}>
        <Card  sx={{ 
    background: 'linear-gradient(135deg, #f0f5f9, #e6ecf2)', // Gradiente da color ghiaccio chiaro a un tono più scuro
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Aggiunge un leggero ombreggiamento per un effetto 3D
    borderRadius: 2 // Arrotonda i bordi
}}>
          <CardContent>
          <Grid container spacing={2}>
  {/* Prima colonna */}
  <Grid item xs={6}>
    <Typography variant="body2" color="text.primary"sx={{ fontSize: 18, fontWeight: 'bold' }}>
      Numero di Siti: {"80"}
    </Typography>
    <Typography variant="body2" color="text.primary" sx={{ fontSize: 18, fontWeight: 'bold' }}>
      Cantieri: {"400"}
    </Typography>
    <Typography variant="body2" color="text.primary" sx={{ fontSize: 18, fontWeight: 'bold' }}>
      Cantieri Attivi: {"80"}
    </Typography>
  </Grid>

  {/* Seconda colonna */}
  <Grid item xs={6}>
    <Typography variant="body2" color="text.primary" sx={{ fontSize: 18, fontWeight: 'bold' }}>
      Cantieri Completati: {"320"}
    </Typography>
    <Typography variant="body2" color="text.primary" sx={{ fontSize: 18, fontWeight: 'bold' }}>
      Ticket Attivi: {"1321"}
    </Typography>
    <Typography variant="body2" color="text.primary" sx={{ fontSize: 18, fontWeight: 'bold' }} >
      Ticket Evasi: {"30"}
    </Typography>
  </Grid>
</Grid>
<Divider sx={{ my: 3, borderBottomWidth: 2, backgroundColor: '#a9a9a9' }}>
</Divider>
                          <SitiChart CantieriAttivi={80} CantieriCompletati={320} />
<Divider sx={{ my: 3, borderBottomWidth: 2, backgroundColor: '#a9a9a9' }}>
</Divider>
                    <EvasioneTicket ticketEvasi={22} TicketAttivi={1321} />
                       
          </CardContent>
        </Card>
        </Grid>
      </Grid>
      
    </div>
  );
}

export default DashBoard;