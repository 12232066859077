import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Assuming axios is installed
import { MenuItem,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,Select,Snackbar,TextField,IconButton,Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material//Delete';
import  Update  from '@mui/icons-material/Update';
import { AddCircleOutline } from '@mui/icons-material';
import {Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import "./ListaUtenti.css"
import { AppBar, Toolbar, Typography, Grid} from '@mui/material';
import Barra from './Barra';
import * as XLSX from 'xlsx'
axios.defaults.baseURL = process.env.REACT_APP_API_URL;


const ricerca= async()=>
    {
        try
        {
            const url1 = "/Utenti"
         //   const url = new URL(url1,  "https://demosgs.it");
           
            //prende dal database i siti con gli indirizzi indirizzi 
            const response = await axios.get(url1);
            return response.data;

        }
        catch(error)
        {
            console.log(error)
            return null;
        }


    };


const ListaUtenti = () => {
    const [utenti, setUtenti] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [IDuser,setIDuser]=useState();
    const [ruoloUtente, setRuoloUtente] = useState('');
    const [utenteSelezionato,setUtenteSelezionato]= useState();
    const [searchInput, setSearchInput] = useState('');
    const [sortOrder, setSortOrder] = useState('asc'); // Ordine di default
    const [searchNome, setSearchNome] = useState('');
    const [searchCognome, setSearchCognome] = useState('');
    const [isBarraOpen, setIsBarraOpen] = useState(false);
   const [openSnackBar,setOpenSnackBar]= useState(false);
   const [severity, setSeverity]=useState('success');
   const [message, setMessage] = useState('');
   const [utentiModificati, setUtentiModificati] = useState([]);
    const toggleBarra = () => {
      setIsBarraOpen(!isBarraOpen); // Updated to toggle the sidebar state based on the passed argument
    };
   
    const gestioneBARRA=()=>{
      console.log("pulsante premuto");
     const a = true;
      setIsBarraOpen(a);
    }
  

    const handleStateChange = (event, utente, campo) => {
        console.log("utente:",utente)
        console.log("campo:",campo);
        console.log("valore",event.target.value);
        const updatedUtenti = utentiModificati.map((item) => {
            if (item.ID === utente.ID) {
                return { ...item, [campo]: event.target.value }; // Aggiorna il campo specifico
            }
            return item;
        });
        console.log("utentiModificati",updatedUtenti);
        setUtentiModificati(updatedUtenti); // Aggiorna lo stato con le modifiche temporanee
    };



    const fetchAggiorna = async (utenteID) => {
        try {
            // Trova l'utente modificato nel tuo array di utenti modificati
            const utenteModificato = utentiModificati.find((item) => item.ID === utenteID);
            
            if (!utenteModificato) {
                console.log("Utente non trovato nelle modifiche.");
                setMessage("Utente non trovato nelle modifiche");
                setSeverity('error');
                setOpenSnackBar(true);
                return;
            }
    
            const url = '/AggiornaUtenti'; // Assicurati che l'URL corrisponda al tuo endpoint
            console.log("Utente modificato da aggiornare:", utenteModificato);
    
            const risposta = await axios.post(url, utenteModificato);
    
            if (risposta.status === 200) {
                console.log("Utente aggiornato con successo");
                setMessage("Utente aggiornato con successo");
                setSeverity('success');
            } else {
                console.log("Errore durante l'aggiornamento dell'utente");
                setMessage("Errore durante l'aggiornamento dell'utente");
                setSeverity('error');
            }
        } catch (error) {
            console.error("Si è verificato un errore durante l'aggiornamento:", error);
            setMessage("Si è verificato un errore durante l'aggiornamento");
            setSeverity('error');
        }
        setOpenSnackBar(true);
    };

const handleNomeSearchChange = (e) => {
  setSearchNome(e.target.value);
};

const handleCognomeSearchChange = (e) => {
  setSearchCognome(e.target.value);
};  
    // ricerca utenti  
    const handleSearchChange = (event) => {
        setSearchInput(event.target.value);
    };
    const filteredData = utentiModificati.filter(utente =>
        utente.nome.toLowerCase().includes(searchNome.toLowerCase()) &&
        utente.cognome.toLowerCase().includes(searchCognome.toLowerCase())
    );


const handleCloseDialog = () => {
    setOpenDialog(false);
}
    

    useEffect(() => 
    {
        const fetchUtenti = async () => {
            const result = await ricerca();
            if (result) {
                setUtenti(result);
                setUtentiModificati(result);
            }
        };
        setIsBarraOpen(false);
        fetchUtenti();
    }, 
    []);

    const fetchElimina = async(userId)=>
    {   
     try
     {     const url = '/EliminaUtenti';
           console.log("elimina=",userId)
           const risposta = await axios.post(url,[userId]);
           console.log(risposta)
           if(risposta.status===200)
           {
                //inserire un 
                setUtenti((prevUtenti) => prevUtenti.filter(utente => utente.ID !== userId))

           }
           else
           {
               //inserire un messaggio
           }
     }
     catch(error)
     {
            
     }
    };


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };



    /*Gestisce l'eliminazione dell'utente*/
    const handleDelete = (userId) => {
       console.log("user iD",userId);
       fetchElimina(userId);
       handleCloseDialog();      
    };

    /*gestisce l'aggiornamento dello stato dell'utente */


    const handleEdit = (utenteID) => {
        // Logica per modificare le informazioni dell'utente con l'ID 'userId'
        fetchAggiorna(utenteID);
  
     
    };

    const handleSort = () => {
        const sortedUsers = utentiModificati.sort((a, b) => 
        {
            if (sortOrder === 'asc') {
                return a.nome.localeCompare(b.nome);
            } else {
                return b.nome.localeCompare(a.nome);
            }
        });

        setUtentiModificati([...sortedUsers]);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    }

    const generatePDF = () => {
        const doc = new jsPDF();
    
        // Define the headers and the data
        const headers = [['Nome', 'Cognome', 'Email', 'Azienda', 'Stato', 'Ruolo']];
        const data = filteredData.map(utente => [
            utente.nome,
            utente.cognome,
            utente.email,
            utente.azienda,
            utente.Attivo,
            utente.Ruolo,
        ]);
    
        // Add autoTable to the document
        autoTable(doc, {
            head: headers,
            body: data,
            theme: 'grid', // Optional theme
            headStyles: { fillColor: [160, 198, 70] }, // Optional header style
        });
    
        // Save the PDF
        doc.save('${fileName}.pdf');
    };


 
    const exportToExcel = () => {
        // Create a new workbook
        const wb = XLSX.utils.book_new();
    
        // Convert your filtered user data to a worksheet
        const ws = XLSX.utils.json_to_sheet(filteredData);
    
        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Utenti');
    
        // Generate the Excel file and trigger the download
        XLSX.writeFile(wb, '${fileName}.xlsx');
    };




    const handleOpenDialog = (utente) => 
    {
        setUtenteSelezionato(utente);
        setOpenDialog(true);
    };




    return (
      <div>
       <Barra isBarraOpen={isBarraOpen} toggleBarra={() => setIsBarraOpen(!isBarraOpen)} />
      <AppBar position="static">
        <Toolbar variant="dense" sx={{ backgroundColor: '#A0C646' }}>
          <Typography variant="h6" noWrap component="div" sx={{ mr: 2 }}>
            Gestione Utenti
          </Typography>
          <Button color="primary" variant="contained" onClick={exportToExcel}>
                        Export to Excel
         </Button>
         <Button color="primary" variant="contained" onClick={generatePDF}>
                        Export to pdf
         </Button>
          <Button color="primary" variant="contained" sx={{ ml: 'auto' }} onClick={() => setIsBarraOpen(true)}>
            <AddCircleOutline />
          </Button>
        </Toolbar>
      </AppBar>


      <Box 
       sx={{ 
        border: '1px solid #ccc', // Bordo del box
        borderRadius: '8px', // Angoli arrotondati
        boxShadow: 2, // Ombra
        padding: '16px', // Padding interno
        margin: '16px', // Margine esterno
    }}
      
      >
        <TableContainer component={Paper}
         
         sx={{
         height: '100vh', // Altezza del TableContainer (puoi cambiarla come preferisci)
         overflowY: 'scroll', // Attiva lo scroll verticale quando necessario
         }}
        >
            
                <Table>
                    <TableHead>
                        <TableRow>
                        <TableCell style={{ width: '200px', padding: '4px 8px' }}>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    Nome
                                </Grid>

                                <Grid item>

                                    <IconButton onClick={handleSort}>
                                        {sortOrder === 'asc' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                                    </IconButton>

                                </Grid>

                                <Grid item xs>
                                    <TextField
                                        variant="outlined"
                                        placeholder=""
                                        style={{ color: 'black' }}
                                        onChange={handleNomeSearchChange}
                                        fullWidth
                                    />
                                </Grid>

                            </Grid>
                            </TableCell >
                            <TableCell style={{ width: '200px', padding: '4px 8px' }}>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item>
                                        Cognome
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={handleSort}>
                                            {sortOrder === 'asc' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs>
                                        <TextField
                                            variant="outlined"
                                            placeholder=""
                                            style={{ color: 'black' }}
                                            onChange={handleCognomeSearchChange}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </TableCell>
                      


                      </TableRow>




                    <TableRow>
                        <TableCell style={{ width: '200px',padding: '4px 8px', lineHeight: '1.5' }}>Nome</TableCell>
                        <TableCell style={{ width:'200px',padding: '4px 8px', lineHeight: '1.5' }}>Cognome</TableCell>
                        <TableCell style={{ minWidth: '200px', maxWidth: '30px',padding: '4px 8px', lineHeight: '1.5' }}>Email</TableCell>
                        <TableCell style={{ minWidth: '100px', maxWidth: '30px',padding: '4px 8px', lineHeight: '1.5' }}>Azienda</TableCell>
                        <TableCell style={{ minWidth: '150px', maxWidth: '30px',padding: '4px 8px', lineHeight: '1.5' }}>Stato</TableCell>
                        <TableCell style={{ minWidth: '150px', maxWidth: '30px',padding: '4px 8px', lineHeight: '1.5' }}>Ruolo</TableCell>
                       
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredData.map((utente,index) => (
                        <TableRow key={index}>
                            <TableCell style={{ minWidth: '20px', maxWidth: '30px',padding: '4px 8px', lineHeight: '1.5' }}>{utente.nome}</TableCell>
                            <TableCell style={{minWidth: '20px', maxWidth: '60px', padding: '4px 8px', lineHeight: '1.5' }}>{utente.cognome}</TableCell>
                            <TableCell style={{minWidth: '20px', maxWidth: '60px', padding: '4px 8px', lineHeight: '1.5' }}>{utente.email}</TableCell>
                            <TableCell style={{minWidth: '20px', maxWidth: '20px', padding: '4px 8px', lineHeight: '1.5' }}>{utente.Azienda}</TableCell>
                            <TableCell style={{ minWidth: '20px', maxWidth: '20px',padding: '4px 8px', lineHeight: '1.5' }}>
                            <Select value={utente.Attivo||''} onChange={(event)=>handleStateChange(event,utente,'Attivo')}>
                            <MenuItem value="SOSPESO">SOSPESO</MenuItem>
                            <MenuItem value="ATTIVO">ATTIVO</MenuItem>
                            <MenuItem value="INATTIVO">INATTIVO</MenuItem>
                            </Select>
                            </TableCell>

                            <TableCell style={{minWidth: '120px', maxWidth: '200px', padding: '4px 8px', lineHeight: '1.5' }}>
                            <Select value={utente.Ruolo ||''} onChange={(event)=>handleStateChange(event,utente,'Ruolo')}>
                            <MenuItem value="ADMIN">ADMIN</MenuItem>
                            <MenuItem value="USERLGS">USERGUEST</MenuItem>
                            <MenuItem value="USERGUEST">USERLGS</MenuItem>
                            </Select>
                            </TableCell>

                                          
                            <TableCell>
                                <Button onClick={() => handleEdit(utente.ID)}   startIcon ={<EditIcon/>} ></Button>
                                <Button onClick={() => handleOpenDialog(utente)} startIcon ={<DeleteIcon/>}></Button> 
                                <Dialog open={openDialog} onClose={handleCloseDialog}>
                                <DialogTitle>Conferma eliminazione</DialogTitle>
                                <DialogContent>
                                <p>Sei sicuro di voler eliminare questo utente?</p>
                                </DialogContent>
                                <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary">Annulla</Button>
                                <Button onClick={()=>handleDelete(utenteSelezionato.ID)} color="secondary">Elimina</Button>
                                </DialogActions>
                                </Dialog> 
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        </Box>
        <Snackbar
                open={openSnackBar}
                autoHideDuration={6000} // Chiude automaticamente dopo 6 secondi
                onClose={handleClose}
                message={message}
                severity={severity}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} // Puoi personalizzare la posizione
                action={
                    <Button color="inherit" onClick={handleClose}>
                        Chiudi
                    </Button>
                }
            />


    </div>
    );
};

export default ListaUtenti;