import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Necessario per le icone dei marker
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const MapComponent = ({ lat, lng }) => {
  console.log("lat:", lat, "lon:", lng);

  const defaultLat = 41.9028; // Latitudine di Roma
  const defaultLng = 12.4964; // Longitudine di Roma

  // Se lat o lng sono null, impostali su Roma
  const position = [
    lat != null && !isNaN(lat) ? lat : defaultLat,
    lng != null && !isNaN(lng) ? lng : defaultLng
  ];



  return (
    <MapContainer
      center={position} // Utilizza le coordinate lat e lng passate come props
      zoom={10}
      style={{ width: '100%', height: '200px' }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={position}>
        <Popup>
          Coordinate: {position[0]}, {position[1]}
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default MapComponent;