import React, { createContext, useContext, useState } from 'react';

const CantiereContext = createContext();

export const CantiereProvider = ({ children }) => {
    const [info, setInfo] = useState({ infoCantiere:"" });

    return (
        <CantiereContext.Provider value={{ info, setInfo }}>
            {children}
        </CantiereContext.Provider>
    );
};

export const useCantiere = () => useContext(CantiereContext);