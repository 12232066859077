import React from 'react';
import "./ChiSiamo.css"
import { Grid, Card, CardMedia, CardContent, Typography,makeStyles } from '@mui/material';
import img from './immagini/C&A.png'



const ChiSiamo = () => {
   
  return (
   <div className='Container1'>
   
    
      <Grid container ={"pippo"}>
        <Grid item xs={3}>
          <CardMedia
            component="img"
            alt="Image"
            height="200"
            image={img}
            title="Image Title"
        
          />
        </Grid>
        <Grid item xs={9}>
          <CardContent>
            <Typography variant="h5" component="div">
              Chi Siamo
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Campagnuolo & Associati Srl is a company
              inspired by the principles of sustainable
              development that leverages technological and
              organizational innovation and the extraordinary
              human and professional heritage at its disposal
              in order to develop constructive solutions.
              </Typography>

              <Typography variant="body2" color="textSecondary" component="p">
              Optimally satisfying the needs of clients,
              achieving growth objectives to increase the
              value of the company and always providing an
              adequate response to the market: this is the
              mission that has distinguished us for years,
              aiming for constant growth with seriousness,
              attention to customer, applying rigid, highly
              qualifying standards.
            </Typography>
          </CardContent>
        </Grid>
      </Grid>
    
     
  </div>


  


  );
}

export default ChiSiamo;