import React, { useState, useCallback, useRef } from "react";
import { Typography, Avatar, IconButton, Box, Drawer, Button, List, Divider, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Link } from 'react-router-dom';
import { CollectionsBookmark, Edit, Help, UploadFile, PhotoCamera } from "@mui/icons-material";
import Assignment from '@mui/icons-material/Assignment';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import Webcam from "react-webcam";
import axios from "axios"; // Importa axios

function Barra({ isBarraOpen, toggleBarra }) {
    const navigate = useNavigate();
    const [avatarUrl, setAvatarUrl] = useState(localStorage.getItem('avatarUrl') || null);
    const [isCameraOpen, setIsCameraOpen] = useState(false);
    const webcamRef = useRef(null); // Crea il riferimento
    const { user } = useUser(); // Ottieni i dati dell'utente
  
    const sendPhotoToBackend = async (imageBlob) => {
        const formData = new FormData();
        formData.append("file", imageBlob, "avatar.jpg");

        try {
            const response = await axios.post("https://tuo-backend-url/upload-avatar", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
           // console.log("Immagine caricata con successo:", response.data);
        } catch (error) {
            //console.error("Errore durante il caricamento dell'immagine:", error);
        }
    };

    const handleTakePhoto = useCallback(() => {
        if (webcamRef.current) {
            const imageSrc = webcamRef.current.getScreenshot();

            if (imageSrc) {
                setAvatarUrl(imageSrc); // Mostra l'immagine scattata

                fetch(imageSrc)
                    .then(res => res.blob())
                    .then(imageBlob => {
                        sendPhotoToBackend(imageBlob);
                    });

                localStorage.setItem('avatarUrl', imageSrc);
            }
        }

        setIsCameraOpen(false); // Chiudi la webcam dopo aver scattato la foto
    }, []);

    const videoConstraints = {
        width: 640,
        height: 480,
        facingMode: "user"
    };

    const handleLogOut = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    const itemsList = (anchor) => (
        <Box
            sx={{
                width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
                backgroundColor: "#09212E",
                height: '100%',
                color: 'white'
            }}
            role="drawer"
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
                {avatarUrl ? (
                    <Avatar 
                        src={avatarUrl} 
                        alt="Avatar utente"
                        sx={{ width: 80, height: 80, mb: 2 }}
                    />
                ) : (
                    <IconButton 
                        color="primary" 
                        aria-label="upload picture" 
                        component="span"
                        onClick={() => setIsCameraOpen(true)} // Apri la webcam quando clicchi sull'icona
                    >
                        <PhotoCamera sx={{ width: 40, height: 40, color: "white" }} />
                    </IconButton>
                )}

                {isCameraOpen && (
                    <Box sx={{ textAlign: 'center', mt: 2 }}>
                        <Webcam
                            audio={false}
                            height={240}
                            screenshotFormat="image/jpeg"
                            width={320}
                            videoConstraints={videoConstraints}
                            ref={webcamRef}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ mt: 2 }}
                            onClick={handleTakePhoto} // Scatta la foto al click del bottone
                        >
                            Scatta Foto
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            sx={{ mt: 2 }}
                            onClick={() => setIsCameraOpen(false)} // Chiudi la webcam senza scattare la foto
                        >
                            Chiudi Webcam
                        </Button>
                    </Box>
                )}

                <Typography variant="h6" sx={{ color: "green", mt: 2 }}>
                    {user.nome} {user.cognome}
                </Typography>
            </Box>

            <List sx={{ mt: 4 }}>
                <ListItemButton sx={{ color: "white" }} component={Link} to="/DashBoard">
                    <ListItemIcon sx={{ color: "white" }}>
                        <Help />
                    </ListItemIcon>
                    <ListItemText primary={"Home"} />
                </ListItemButton>
                <ListItemButton sx={{ color: "white" }} component={Link} to="/Lista Siti">
                    <ListItemIcon sx={{ color: "white" }}>
                        <CollectionsBookmark />
                    </ListItemIcon>
                    <ListItemText primary={"Siti"} />
                </ListItemButton>
                <ListItemButton sx={{ color: "white" }} component={Link} to="/Agenda">
                    <ListItemIcon sx={{ color: "white" }}>
                        <UploadFile />
                    </ListItemIcon>
                    <ListItemText primary={"Agenda"} />
                </ListItemButton>
                <ListItemButton sx={{ color: "white" }} component={Link} to="/Utenti">
                    <ListItemIcon sx={{ color: "white" }}>
                        <Edit />
                    </ListItemIcon>
                    <ListItemText primary={"Utenti"} />
                </ListItemButton>
                <ListItemButton sx={{ color: "white" }} component={Link} to="/TaskManager">
                <ListItemIcon sx={{ color: "white" }}>
                    <Assignment />
                </ListItemIcon>
                <ListItemText primary={"Task Manager"} />
            </ListItemButton>
            </List>
            <Divider />
            <Button
                sx={{
                    backgroundColor: "blue",
                    color: "white",
                    borderRadius: 10,
                    textAlign: "center",
                    padding: 1,
                    margin: 2,
                }}
                onClick={handleLogOut}
            >
                Log out
            </Button>
        </Box>
    );

    return (
        <div drawer anchor="right">
            <center>
                <Drawer
                    anchor={"right"}
                    open={isBarraOpen}
                    onClose={() => toggleBarra(false)}
                >
                    {itemsList("right")}
                </Drawer>
            </center>
        </div>
    );
}

export default Barra;