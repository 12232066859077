import React, { useState } from 'react';
import SezioneGenerale from './SezioneGenerale';
import ImpresaAffidataria from './ImpresaAffidataria';
import VerificaGestioneAttivita from './VerificaGestioneAttivita';
import VerificaAttivitaTitoloIV from './VerificaAttivitaTitoloIV';


function VerbaleVerifica() {
  const [datiIspezione, setDatiIspezione] = useState({
    // Inizializza i dati con valori di default
  });

  const handleChange = (event) => {
    setDatiIspezione({
      ...datiIspezione,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Invia i dati del form al server o esegui altre azioni
    console.log(datiIspezione);
  };

  return (
    <form onSubmit={handleSubmit}>
     
      <SezioneGenerale dati={datiIspezione} onChange={handleChange}/>
      <ImpresaAffidataria dati={datiIspezione} onChange={handleChange}/>
      <VerificaGestioneAttivita dati={datiIspezione} onChange={handleChange}/>
      <VerificaAttivitaTitoloIV dati={datiIspezione} onChange={handleChange}/> 
      <button type="submit">Invia</button>
    </form>
  );
}

export default VerbaleVerifica;