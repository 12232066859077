import React from 'react';
import { Box, Typography, Link, Container, Grid } from '@mui/material';
import { Facebook, Twitter, LinkedIn } from '@mui/icons-material';
const Footer = () => {
  return (
    <Box
      component="footer"
      
        sx={{
            background: 'linear-gradient(to right, #3498db 20%, #8CFF00 80%)', 
            color: '#fff',  // Colore del testo bianco
            padding: '5px 0',  // Ridotto il padding verticale
            marginTop: 'auto',
            borderTop: '1px solid #e0e0e0',
            fontSize: '8px',  // Impostato font-size globale
            position: 'relative',
            bottom: 0,
            width: '100%'
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={1}>  {/* Ridotto lo spazio tra gli elementi */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={{ fontSize: '10px' }}>Azienda</Typography>
            <Link href="#" color="inherit">Lavora con noi</Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={{ fontSize: '10px' }}>Supporto</Typography>
            <Link href="#" color="inherit">Privacy Policy</Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={{ fontSize: '10px' }}>Servizi</Typography>
            <Link href="#" color="inherit">Prodotti</Link><br />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={{ fontSize: '10px' }}>Seguici</Typography>
            <Box sx={{ display: 'flex', gap: '10px', marginTop: '5px' }}>  {/* Disposizione orizzontale con gap */}
              <Link href="#" color="inherit">
                <Facebook sx={{ fontSize: '12px' }} />  {/* Icona Facebook */}
              </Link>
              <Link href="#" color="inherit">
                <Twitter sx={{ fontSize: '12px' }} />  {/* Icona Twitter */}
              </Link>
              <Link href="#" color="inherit">
                <LinkedIn sx={{ fontSize: '12px' }} />  {/* Icona LinkedIn */}
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Box textAlign="center" marginTop={1}> {/* Ridotto il margine superiore */}
          <Typography variant="body2" color="textSecondary" sx={{ fontSize: '6px' }}>
            © {new Date().getFullYear()} Tua Azienda. Tutti i diritti riservati.
            </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;