import React, { useState } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { format, parse, startOfWeek, getDay } from "date-fns";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from 'date-fns/locale/it'; // Importa la localizzazione italiana da date-fns
import "./Agenda.css";
// Registra la localizzazione italiana per il DatePicker
registerLocale('it', it);

// Configura la localizzazione del calendario per l'italiano
const locales = {
  'it': it,
};

const localizer = dateFnsLocalizer({
  format: (date, formatStr, options) => format(date, formatStr, { ...options, locale: it }), // Usa il locale italiano
  parse: (value, formatStr, options) => parse(value, formatStr, new Date(), { ...options, locale: it }),
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }), // Inizio settimana da lunedì
  getDay,
  locales,
});

const Agenda = () => {
  const [eventi, setEventi] = useState([]);
  const [nuovoEvento, setNuovoEvento] = useState({ titolo: "", inizio: null, fine: null });

  const handleAggiungiEvento = () => {
    const { inizio, fine, titolo } = nuovoEvento;

    // Controllo che le date siano valide
    if (inizio && fine && inizio < fine && titolo) {
      setEventi([...eventi, { title: titolo, start: inizio, end: fine }]);
      setNuovoEvento({ titolo: "", inizio: null, fine: null }); // Reset fields
    } else {
      alert("Per favore, inserisci un titolo e date valide.");
    }

    console.log("Eventi:", eventi);
  };

  return (
    <div style={{ padding: 20 }}>
      <Grid container spacing={3}>
        {/* Sidebar con selezione della data */}
        <Grid item xs={12} sm={3}>
          <Paper style={{ padding: 16 }}>
            <Typography variant="h6">Aggiungi Evento</Typography>
            <input
              type="text"
              placeholder="Titolo"
              value={nuovoEvento.titolo}
              onChange={(e) => setNuovoEvento({ ...nuovoEvento, titolo: e.target.value })}
              style={{ width: "100%", marginBottom: 10, color: "black", backgroundColor: "white" }} // Modifica qui
            />
            <DatePicker
              locale="it" // Localizzazione italiana per il selettore data
              dateFormat="dd/MM/yyyy" // Formato della data in italiano
              value={nuovoEvento.inizio}
              placeholderText="Data Inizio"
              selected={nuovoEvento.inizio}
              onChange={(inizio) => setNuovoEvento({ ...nuovoEvento, inizio })}
              className="date-picker-input" // Applica la classe CSS
            />
            <DatePicker
              locale="it" // Localizzazione italiana per il selettore data
              dateFormat="dd/MM/yyyy" // Formato della data in italiano
              placeholderText="Data Fine"
              value={nuovoEvento.fine}
              selected={nuovoEvento.fine}
              onChange={(fine) => setNuovoEvento({ ...nuovoEvento, fine })}
              className="date-picker-input"
            />
            <button style={{ width: "100%", padding: 10 }} onClick={handleAggiungiEvento}>
              Aggiungi Evento
            </button>
          </Paper>
        </Grid>

        {/* Calendario */}
        <Grid item xs={12} sm={9}>
          <Paper style={{ padding: 16 }}>
            <Calendar
              localizer={localizer}
              events={eventi}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
              messages={{
                next: "Prossimo",
                previous: "Precedente",
                today: "Oggi",
                month: "Mese",
                week: "Settimana",
                day: "Giorno",
                agenda: "Agenda",
                date: "Data",
                time: "Ora",
                event: "Evento",
                noEventsInRange: "Nessun evento in questo intervallo",
              }}
              eventPropGetter={(event) => ({
                style: { color: 'black' }
              })}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Agenda;