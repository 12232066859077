import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import { useSnackbar } from 'notistack';

const TaskManager = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [taskTitle, setTaskTitle] = useState('');
  const [taskDescription, setTaskDescription] = useState('');
  const [taskDueDate, setTaskDueDate] = useState('');
  const [assignedTo, setAssignedTo] = useState('');
  const [tasks, setTasks] = useState([]);
  const [filter, setFilter] = useState('all');

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    const response = await fetch('http://localhost:3001/tasks');
    const data = await response.json();
    setTasks(data);
  };

  const handleAddTask = async () => {
    if (taskTitle.trim() && taskDescription.trim()) {
      const newTask = { title: taskTitle, description: taskDescription, dueDate: taskDueDate, assignedTo };
      await fetch('http://localhost:3001/tasks', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newTask),
      });
      enqueueSnackbar('Task aggiunto con successo!', { variant: 'success' });
      fetchTasks();
      setTaskTitle('');
      setTaskDescription('');
      setTaskDueDate('');
      setAssignedTo('');
    }
  };

  const handleDeleteTask = async (id) => {
    await fetch(`http://localhost:3001/tasks/${id}`, { method: 'DELETE' });
    enqueueSnackbar('Task eliminato con successo!', { variant: 'error' });
    fetchTasks();
  };

  const handleCompleteTask = async (id, completed) => {
    await fetch(`http://localhost:3001/tasks/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ completed: !completed }),
    });
    enqueueSnackbar(`Task ${completed ? 'completato' : 'ripristinato'} con successo!`, { variant: 'info' });
    fetchTasks();
  };

  const filteredTasks = tasks.filter((task) => {
    if (filter === 'completed') return task.completed;
    if (filter === 'notCompleted') return !task.completed;
    return true;
  });

  return (
    <Box sx={{
         width: '100%', 
         maxWidth: 600, 
         margin: '20px auto', 
         height: '100vh'
         }}>
      <TextField
        label="Titolo del Task"
        variant="outlined"
        fullWidth
        value={taskTitle}
        onChange={(e) => setTaskTitle(e.target.value)}
        sx={{ marginBottom: '10px' }}
      />
      <TextField
        label="Descrizione del Task"
        variant="outlined"
        fullWidth
        value={taskDescription}
        onChange={(e) => setTaskDescription(e.target.value)}
        sx={{ marginBottom: '10px' }}
      />
      <TextField
        label="Data di Scadenza"
        variant="outlined"
        type="date"
        fullWidth
        value={taskDueDate}
        onChange={(e) => setTaskDueDate(e.target.value)}
        sx={{ marginBottom: '10px' }}
      />
      <FormControl fullWidth sx={{ marginBottom: '10px' }}>
        <InputLabel id="assigned-to-label">Assegnato a</InputLabel>
        <Select
          labelId="assigned-to-label"
          value={assignedTo}
          onChange={(e) => setAssignedTo(e.target.value)}
        >
          <MenuItem value="User 1">User 1</MenuItem>
          <MenuItem value="User 2">User 2</MenuItem>
          <MenuItem value="User 3">User 3</MenuItem>
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddTask}
        sx={{ marginBottom: '20px' }}
      >
        Aggiungi Task
      </Button>
      <FormControl fullWidth sx={{ marginBottom: '20px' }}>
        <InputLabel>Filtra per stato</InputLabel>
        <Select value={filter} onChange={(e) => setFilter(e.target.value)}>
          <MenuItem value="all">Tutti</MenuItem>
          <MenuItem value="completed">Completati</MenuItem>
          <MenuItem value="notCompleted">Non Completati</MenuItem>
        </Select>
      </FormControl>

      <List>
        {filteredTasks.map((task) => (
          <ListItem key={task.id} divider>
            <ListItemText
              primary={task.title}
              secondary={`${task.description} (Scadenza: ${task.dueDate})`}
              style={{ textDecoration: task.completed ? 'line-through' : 'none' }}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="complete" onClick={() => handleCompleteTask(task.id, task.completed)}>
                <CheckIcon color={task.completed ? 'success' : 'action'} />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteTask(task.id)}>
                <DeleteIcon color="error" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default TaskManager;