import React, { useState } from 'react';
import { TextField, Button, Typography, Grid, Box, Container, IconButton, InputAdornment, Card, CardContent } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material'; 
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import axios from 'axios'; 
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('');

  const navigate = useNavigate();
  const { setUser } = useUser(); 
  const handleSubmit = async (event) => {
    event.preventDefault();
   
    const data = { email, password };

   const url='/login'

    try {
      const response = await axios.post(url, data);
     //console.log("risposta", response.data);
      if (response.data.userExists.state) 
      {
        localStorage.setItem('token', response.data.token);
        setUser({nome:response.data.userExists.nome, cognome:response.data.userExists.cognome });
        setMessage('Login successful');
        navigate('/DashBoard');
      } else {
       // console.log("ammt");
        setMessage('Registration successful. Please login to continue.');
        setEmail('');
        setPassword('');
        
      }
    } catch (error) {
      setMessage('Login failed');
    }
  };

  const toggleForm = () => {
    navigate('/Register');
    setMessage('');
  };

  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div
      className="login-container"
      style={{
        height: '100vh',
        backgroundImage: 'url(./immagini/login.jpg)', // Percorso relativo all'immagine
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
   
   

    <Container maxWidth="sm"  sx={{
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent background
    }}>
      <Box 
        sx={{ 
          marginTop: 8, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center' 
        }}
      >
        <Card variant="outlined" sx={{ width: '100%', borderRadius: 3,backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
          <CardContent>
          <Box component="div" sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
              <img
               src="logoSGS.png" // Percorso relativo al logo
               alt="SGS Logo"
               style={{ maxWidth: '100%', height: 'auto' }} // Imposta la larghezza massima e l'altezza automatica
              />
           </Box>


            <Typography component="h1" variant="h5" align="center" sx={{ mb: 3 }}>
              Login
            </Typography>
            
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    variant="outlined"
                    sx={{ 
                          borderRadius: 3,
                          bgcolor: 'background.paper',
                          width: '100%', // Mantieni la stessa larghezza
                          minWidth: '300px' // Stessa larghezza minima
                          
                       }} // Colore di sfondo del TextField
                       inputProps={{
                        style: { height: '45px' } // Imposta un'altezza fissa
                      }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    variant="outlined"
                    sx={{ 
                      borderRadius: 3,
                      bgcolor: 'background.paper', 
                      width: '100%', // Mantieni la stessa larghezza
                      minWidth: '300px' // Stessa larghezza minima
                      }} // Colore di sfondo del TextField
                      
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />} 
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, bgcolor: '#007bff', '&:hover': { bgcolor: '#0056b3' } , borderRadius: 3}} // Colore personalizzato
              >
                Login
              </Button>
              
              <Button
                fullWidth
                variant="text"
                sx={{ mt: 1 }}
                onClick={toggleForm}
                color="primary"
              >
                registrati
              </Button>

              {message && (
                <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                  {message}
                </Typography>
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
    </div>
  );
};

export default Login;
