import React, { useEffect, useState,useReducer } from 'react';
import { Grid, Paper, Typography, Button, TextField, Select, MenuItem ,Box,Toolbar, AppBar} from '@mui/material'
import { useCantiere } from './CantiereContext';
import VerificaAttivitaTitoloIV from './VerificaAttivitaTitoloIV';
import VisitaIspettiva from './VisitaIspettiva';
import LeggiImpresa from './LeggiImpresa';
import Barra from  './Barra';
import { AddCircleOutline } from '@mui/icons-material';
import { DocumentScanner, Work, Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
const GestioneCantiere = () => {
  
let data={};

const [isBarraOpen, setIsBarraOpen] = useState(false);

const { info } = useCantiere(); // Ottieni i dati dell'utente
const navigate =useNavigate();
if (typeof info === 'string') {
   data = JSON.parse(info); // Esegui il parsing solo se è una stringa
  console.log("stringa",data);
} else {
   data = info; // Usa direttamente se è un oggetto
  console.log("oggetto",data);
}

const toggleBarra = () => setIsBarraOpen(prev => !prev);

const [selezione, setSelezione] = useState(null);

// Funzioni di gestione del click dei pulsanti
const handleDocumentazioneClick = () => {
  setSelezione('visita');
};

const handleAttivitaTitoloIVClick = () => {
  setSelezione('attivita');
};

const handleProgrammaVisitaIspettiva = () => {
  navigate("/Agenda");
};


return (
  
   <div 
   style={{ 
    
    backgroundColor: '#39414C', 
    height: '150vh', 
    }}>
      <Barra isBarraOpen={isBarraOpen} toggleBarra={toggleBarra} />
      <AppBar position="static">
        <Toolbar sx={{ backgroundColor: '#A0C646' }}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            LGS - Informazioni Sito
          </Typography>
          <Button color="primary" variant="contained" sx={{ ml: 2 }} onClick={toggleBarra}>
            <AddCircleOutline />
          </Button>
        </Toolbar>
      </AppBar>
    <div>

    <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ padding: '20px' }}>
    <Grid item xs={12} sm={4}>
        <Button
          onClick={handleDocumentazioneClick}
          variant="contained"
          startIcon={<DocumentScanner />}
          sx={{
            backgroundColor: '#1976d2',
            color: 'white',
            borderRadius: '8px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
            padding: '10px 20px',
            '&:hover': {
              backgroundColor: '#115293',
              boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
            },
          }}
          fullWidth
        >
          Documentazione
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          onClick={handleAttivitaTitoloIVClick}
          variant="contained"
          startIcon={<Work />}
          sx={{
            backgroundColor: '#388e3c',
            color: 'white',
            borderRadius: '8px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
            padding: '10px 20px',
            '&:hover': {
              backgroundColor: '#2e7d32',
              boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
            },
          }}
          fullWidth
        >
          Attivita Titolo IV
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          onClick={handleProgrammaVisitaIspettiva}
          variant="contained"
          startIcon={<Visibility />}
          sx={{
            backgroundColor: '#f57c00',
            color: 'white',
            borderRadius: '8px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
            padding: '10px 20px',
            '&:hover': {
              backgroundColor: '#ef6c00',
              boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
            },
          }}
          fullWidth
        >
          Programma Visita Ispettiva
        </Button>
      </Grid>
      </Grid>
    </div>

    <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ padding: '20px' }}>
   
      <Grid item >
      <Box 
          sx={{
            width: '100%',      // Larghezza del Box
            maxHeight: 400,      // Altezza massima per attivare lo scroll
            overflowY: 'hidden',   // Scroll verticale
            p: 2,                // Padding interno
            backgroundColor: 'grey.200',  // Colore di sfondo
            border: '2px solid orange',     // Bordo
            margin:'10px'
          }}
        
        >

          <Grid item >
            <Paper elevation={1}>
              <Typography variant="h5" gutterBottom>
                Gestione Cantiere
              </Typography>
              
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="nome"
                      name="Indirizzo"
                      value={data.nomeCantiere}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="dataInizio"
                      name="dataInizio"
                      label="Data Inizio Lavori"
                      value={data.dataInizio}
                      fullWidth
                    />
                
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="ODA"
                      name="ODA"
                      label="ODA"
                      value={data.ordine_acquisto}
                      fullWidth
                    /> 
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="dataInizio"
                      name="dataInizio"
                      label="Data Inizio Lavori"
                      value={data.dataInizio}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="dataFine"
                      name="dataFine"
                      label="Data Inizio Lavori"
                      value={data.dataFine}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="descrizione"
                      name="descrizione"
                      label="Descrizione"
                      value={data.descrizione}
                      fullWidth
                    />
                  
                </Grid>
                </Grid>
              
            </Paper>
          </Grid>
        </Box>
        <Box 

          sx={{

            width: '100%',
            height: '300px', // Altezza fissa per tutti i box
            overflowY: 'auto',
            p: 1,
            backgroundColor: 'grey.200',
            border: '2px solid orange',
            marginTop: '10px',
            marginBottom: '10px',
            align:'center',
          }}
        >
          <LeggiImpresa/>
        </Box >
      </Grid>
      
      
      {/* Rendering condizionale in base allo stato 'selezione' */}
      
       <Box
       sx={{

        width: '100%',
        height: '400px', // Altezza fissa per tutti i box
        overflowY: 'auto',
        p: 1,
        backgroundColor: 'grey.200',
        border: '2px solid orange',
        marginTop: '10px',
        marginBottom: '30px',
        align:'center',
       }}
      
      >
        <Grid  item>

          {selezione === 'visita' && 
           <VisitaIspettiva />}
        {selezione === 'attivita' && (

           
              <VerificaAttivitaTitoloIV />
           
          
        )}
      </Grid>
      </Box>
    </Grid>
  </div>
);
};
export default GestioneCantiere;