import React, { useState, useEffect } from 'react';
import { TableRow, TableCell, Checkbox, TextField, Box } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

function Riga({ onDataChange,row }) {
  const [RIF,setRif] = useState(row.RIF);
  const [label, setLabel]=useState(row.label);
  const [isCheckedSI, setIsCheckedSIState] = useState(row.isCheckedSI);
  const [isCheckedNO, setIsCheckedNOState] = useState(row.isCheckedNO);
  const [isCheckedNA, setIsCheckedNAState] = useState(row.isCheckedNA);
  const [note, setNoteState] = useState(row.note);
  const [osservazioni, setOsservazioniState] = useState(row.osservazioni);
  const [date, setDateState] = useState(row.date);

  useEffect(() => {
    // Passa i dati aggiornati al genitore
    onDataChange(row.id, {
      RIF,
      label, 
      isCheckedSI, 
      isCheckedNO, 
      isCheckedNA, 
      date,
      note, 
      osservazioni, 
      
    });
  }, [RIF,label, isCheckedSI, isCheckedNO, isCheckedNA, date, note,osservazioni,row.id]); // Trigger quando uno di questi cambia

  const handleCheckboxChange = (type) => {
    switch (type) {
      case 'SI':
        setIsCheckedSIState(prev => !prev);
        setIsCheckedNOState(false);
        setIsCheckedNAState(false);
        break;
      case 'NO':
        setIsCheckedNOState(prev => !prev);
        setIsCheckedSIState(false);
        setIsCheckedNAState(false);
        break;
      case 'NA':
        setIsCheckedNAState(prev => !prev);
        setIsCheckedSIState(false);
        setIsCheckedNOState(false);
        break;
      default:
        break;
    }
  };

  const handleNoteChange = (event) => setNoteState(event.target.value);
  const handleOsservazioniChange = (event) => setOsservazioniState(event.target.value);
  const handleDateChange = (newDate) => setDateState(newDate);

  const isRowGreen = isCheckedSI && date !== null;
  const isRowOrange = isCheckedNO;
  const rowColor = isRowGreen ? 'lightgreen' : isRowOrange ? 'orange' : 'white';

  return (
    <TableRow style={{ backgroundColor: rowColor, border: '1px solid black' }}>
      <TableCell>{RIF}</TableCell>
      <TableCell>{label}</TableCell>
      <TableCell>
        <Box display="flex" justifyContent="center">
          <Checkbox checked={isCheckedSI} onChange={() => handleCheckboxChange('SI')} />
        </Box>
      </TableCell>
      <TableCell>
        <Box display="flex" justifyContent="center">
          <Checkbox checked={isCheckedNO} onChange={() => handleCheckboxChange('NO')} />
        </Box>
      </TableCell>
      <TableCell>
        <Box display="flex" justifyContent="center">
          <Checkbox checked={isCheckedNA} onChange={() => handleCheckboxChange('NA')} />
        </Box>
      </TableCell>
      <TableCell>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disableToolbar
            inputFormat="dd/MM/yyyy"
            value={date}
            onChange={handleDateChange}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </LocalizationProvider>
      </TableCell>
      <TableCell>
        <TextField fullWidth value={note} onChange={handleNoteChange} />
      </TableCell>
      <TableCell>
        <TextField fullWidth value={osservazioni} onChange={handleOsservazioniChange} />
      </TableCell>
    </TableRow>
  );
}

export default Riga