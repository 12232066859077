import React, { useState } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Grid
} from '@mui/material';
import './FiltroBar.css';

const FiltroBar = ({ onFilterChange}) => {


  const initialFilters = {
    nome: '',
    nazione: '',
    cluster: '',
    progressoMin: '',
    progressoMax: '',
    projectManager: ''
  };

  const [filters, setFilters] = useState(initialFilters);

  // Funzione per gestire il cambiamento dei filtri
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  // Funzione per applicare i filtri
  const handleApplyFilters = () => {
    onFilterChange(filters);
  };

  const resetFilters=()=>{
    setFilters(initialFilters);
    onFilterChange(initialFilters); // Notifica il cambio dei filtri
  }


  return (
    <div className='filtro-bar' style={{
       padding: '10px', 
       marginTop: '10px',
       marginBottom:'10px',
       border:'solid 1px color:black'
       }}>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} md={1.5}>
          <TextField
            label="Nome sito"
            name="nome"
            variant="outlined"
            size="small"
            fullWidth
            value={filters.nome}
            onChange={handleChange}
            sx={{ backgroundColor: '#FFFFFF', borderRadius: 5, border: 'none' }} 
          />
        </Grid>

        <Grid item xs={12} sm={3} md={1.5}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Nazione</InputLabel>
            <Select
              name="nazione"
              value={filters.nazione}
              onChange={handleChange}
              label="Nazione"
              sx={{ backgroundColor: '#FFFFFF', borderRadius: 5, border: 'none' }} 
            >
              <MenuItem value="">Tutte</MenuItem>
              <MenuItem value="Italia">Italia</MenuItem>
              <MenuItem value="USA">USA</MenuItem>
              <MenuItem value="Francia">Francia</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3} md={1.5}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Cluster</InputLabel>
            <Select
              name="cluster"
              value={filters.cluster}
              onChange={handleChange}
              label="Cluster"
              sx={{ backgroundColor: '#FFFFFF', borderRadius: 5, border: 'none' }}
            >
              <MenuItem value="">Tutte</MenuItem>
              <MenuItem value="NORD">NORD</MenuItem>
              <MenuItem value="NORD EST">NORD EST</MenuItem>
              <MenuItem value="NORD OVEST">NORD OVEST</MenuItem>
              <MenuItem value="CENTRO">CENTRO</MenuItem>
              <MenuItem value="TIRRENICA">TIRRENICA</MenuItem>
              <MenuItem value="SUD">SUD</MenuItem>
              <MenuItem value="SUD EST">SUD EST</MenuItem>
              <MenuItem value="SUD OVEST">SUD OVEST</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3} md={1.5}>
          <TextField
            label="Progresso Min %"
            name="progressoMin"
            variant="outlined"
            size="small"
            type="number"
            value={filters.progressoMin}
            onChange={handleChange}
            sx={{ backgroundColor: '#FFFFFF', borderRadius: 5, border: 'none' }}
          />
        </Grid>

        <Grid item xs={12} sm={3} md={1.5}>
          <TextField
            label="Progresso Max %"
            name="progressoMax"
            variant="outlined"
            size="small"
            type="number"
            fullWidth
            value={filters.progressoMax}
            onChange={handleChange}
            sx={{ backgroundColor: '#FFFFFF', borderRadius: 5, border: 'none' }} 
          />
        </Grid>

        <Grid item xs={12} sm={3} md={1.5}>
          <TextField
            label="Project Manager"
            name="projectManager"
            variant="outlined"
            size="small"
            fullWidth
            value={filters.projectManager}
            onChange={handleChange}
            sx={{ backgroundColor: '#FFFFFF', borderRadius: 5, border: 'none' }} 
          />
        </Grid>

        <Grid item xs={12} sm={3} md={1.5}>
          <Button
            variant="contained"
            color="primary"
            sx={{ borderRadius: 5, minWidth: 'auto', border: 'none' }} 
            onClick={handleApplyFilters} // Applica i filtri
          >
            Applica filtri
          </Button>
        </Grid>

        <Grid item xs={12} sm={3} md={1.5}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ borderRadius: 5, minWidth: 'auto', border: 'none' }} 
            onClick={resetFilters}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default FiltroBar;
