import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import DettagliIspezione from './DettagliIspezione';
import DataRow from './DataRow';
import "./ispezione.css";

const Ispezione = () => {
  const data = [
    // ... dati ...
  ];

  return (
    <div>
      <DettagliIspezione />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Documento</TableCell>
              <TableCell>Presente</TableCell>
              <TableCell>File Upload</TableCell>
              <TableCell>Note</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(({ label }) => (
              <DataRow key={label} label={label} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Ispezione;