import React from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

const StatoCantieriPieChart = ({ cantieriAttivi, cantieriCompletati, cantieriInProgramma, width = 300, height = 300, outerRadius = 60 }) => {
  const data = [
    { name: 'Cantieri Attivi:', value: cantieriAttivi },
    { name: 'Cantieri Completati:', value: cantieriCompletati },
    { name: 'Cantieri In Programma:', value: cantieriInProgramma },
  ];

  return (
    <PieChart width={width} height={height}>
      <Pie
        data={data}
        cx={width / 3}
        cy={height / 2}
        labelLine={false}
        label={({ name, percent, x, y }) => (
          <text
            x={x}
            y={y}
            style={{ fontWeight: 'bold', fontSize: '8px' }}
            textAnchor="middle"
            dominantBaseline="central"
          >
            {`${name} ${(percent * 100).toFixed(0)}%`}
          </text>
        )}
        outerRadius={outerRadius}
        innerRadius={outerRadius/2}
        fill="#8884d8"
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
      <Legend
        layout="vertical"
        verticalAlign="bottom"
        align="right"
        wrapperStyle={{
          padding: 5,
          backgroundColor: '#fff',
          borderRadius: 5,
          border: '1px solid #ccc',
          fontSize: '12px',
        }}
      />
    </PieChart>
  );
};

const StatoCantieriCard = ({ cantieriAttivi, cantieriCompletati, cantieriInProgramma }) => {
  const cantieriTotali = cantieriAttivi + cantieriCompletati + cantieriInProgramma;

  return (
    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Card>
        <CardContent style={{ display: 'flex', alignItems: 'left' }}>
          <div style={{ flex: 1 }}>
            <Typography variant="h6">Stato Cantieri:</Typography>
            <Typography variant="body1">Cantieri Attivi: {cantieriAttivi}</Typography>
            <Typography variant="body1">Cantieri Completati: {cantieriCompletati}</Typography>
            <Typography variant="body1">Cantieri In Programma: {cantieriInProgramma}</Typography>
            <Typography variant="body1">Cantieri Totali: {cantieriTotali}</Typography>
          </div>
          <StatoCantieriPieChart
            cantieriAttivi={cantieriAttivi}
            cantieriCompletati={cantieriCompletati}
            cantieriInProgramma={cantieriInProgramma}
            width={600}
            height={400}
            outerRadius={150}
          />
        </CardContent>
      </Card>
    </Grid>
  );
};

export default StatoCantieriCard;