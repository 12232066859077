import React, { useState, useCallback } from "react";
import Barra from "./Barra";
import CreaCantiere from "./creaCantiere"; // Assicurati che il nome del file sia corretto
import { AppBar, Toolbar, Typography, Button } from "@mui/material";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline"; // Assicurati di importare l'icona
import VerificaAttivitaTitoloIV from "./VerificaAttivitaTitoloIV";
import VerbaleVerifica from "./VerbaleVerifica";
const CantiereProva = () => {
    // Definisci lo stato e le funzioni necessarie
    const [isBarraOpen, setIsBarraOpen] = useState(false);

    const toggleBarra = useCallback(() => {
        setIsBarraOpen(prev => !prev);
    }, []);

    return (
        <div>
            <Barra isBarraOpen={isBarraOpen} toggleBarra={toggleBarra} />
            <AppBar position="static">
                <Toolbar sx={{ backgroundColor: '#A0C646' }}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        LGS - Crea un Nuovo Cantiere
                    </Typography>
                    <Button color="primary" variant="contained" sx={{ ml: 2 }} onClick={toggleBarra}>
                        <AddCircleOutline />
                    </Button>
                </Toolbar>
            </AppBar>
            <CreaCantiere/>
        
        </div>
    );
};

export default CantiereProva;