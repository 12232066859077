
import React, { useEffect, useState,useReducer } from 'react';
import { TextField, Button, Typography, Grid, Box, IconButton, InputLabel, MenuItem, Select, Divider,Toolbar,AppBar, Grow} from '@mui/material';
import { useNotifications } from '../NotificationContext';
import { useLocation } from 'react-router-dom';
import Impresa from './Impresa';
import 'react-datepicker/dist/react-datepicker.css';
import { AddCircleOutline } from '@mui/icons-material';
import DeleteIcon from '@mui/material/Icon';
import { Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import VerificaAttivitaTitoloIV from './VerificaAttivitaTitoloIV';
import { format, parse, startOfWeek, getDay } from "date-fns";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {dateFnsLocalizer } from "react-big-calendar";
import it from 'date-fns/locale/it'; // Importa la localizzazione italiana da date-fns
axios.defaults.baseURL = process.env.REACT_APP_API_URL;


// Registra la localizzazione italiana per il DatePicker
registerLocale('it', it);

// Configura la localizzazione del calendario per l'italiano
const locales = {
  'it': it,
};

const localizer = dateFnsLocalizer({
  format: (date, formatStr, options) => format(date, formatStr, { ...options, locale: it }), // Usa il locale italiano
  parse: (value, formatStr, options) => parse(value, formatStr, new Date(), { ...options, locale: it }),
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }), // Inizio settimana da lunedì
  getDay,
  locales,
});




function CreaCantiere() {

  const initialState = {
    nomePersonaleSicurezza: '',
    cognomePersonaleSicurezza: '',
    emailPersonaleSicurezza: '',
    telefonoPersonaleSicurezza: '',
    indirizzoPostaCertificata: '',
    cognomePreposto: '',
    nomePrepostoImpresa: '',
    emailPreposto: '',
    telefonoPreposto: '',
    indirizzoSedeLegale: '',
    tipoImpresa: '',
    ragioneSociale: '',
    telefonoUfficio: '',
    emailImpresa: ''
  };
  const [checked, setChecked] = useState(false);
  const handleToggle = () => {
    setChecked((prev) => !prev);
  };
  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return {
          ...state,
          [action.field]: action.value
        };
      default:
        return state;
    }
  };


  const [state, dispatch] = useReducer(reducer, initialState);

  const [nome, setNome] = useState('');
  
  const [indirizzo, setIndirizzo] = useState('');
  
  const [cluster, setCluster] = useState('');
  
  const [descrizione, setDescrizione] = useState('');
  
  const [documenti, setDocumenti] = useState([]);
  
  const [startDate, setStartDate] = useState(null);
  
  const [endDate, setEndDate] = useState(null);
  
  const [importolavori, setImportoLavori] = useState('');
  
  const [ODA, setODA] = useState('');
  
  const [dataInizioLavori, setDataInizioLavori] = useState(null);
  
  const [dataFineLavori, setDataFineLavori] = useState(null);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const [nomeRL, setNomeRL] = useState('');
  
  const[cognomeRL, setCognomeRL] = useState('');
  
  const[emailRL, setEmailRL] = useState('');
  
  const [telefonoRL, setTelefonoRL] = useState('');

  const { getNotifications } = useNotifications();

  const [isBarraOpen, setIsBarraOpen] = useState(false);

  const location = useLocation();
  
  const sito = location.state?.sito || {}; // Ottieni i dati del sito
  
  const site_ID = location.state?.ID || ''; // Ottieni l'ID del cantiere
  
  const [imprese, setImprese] = useState([initialState]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  
  const [snackbarMessage, setSnackbarMessage] = useState('');
  
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success', 'error', etc.
  

 // const [datiRicevuti,setDatiRicevuti] = useState([]);
  
 //money money 
 const formatCurrency = (value) => {
  if (!value) return "";

  const number = parseFloat(value);

  if (isNaN(number)) return "";

  return new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};


  const navigate = useNavigate();

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
   
    navigate('/site/${site_ID}');
  };


  const gestisciDati = (dati) => {
    //setDatiRicevuti(dati); // Aggiorna lo stato con i dati ricevuti
    //console.log("Dati ricevuti dal figlio:", dati);
};

  const handleAddImpresa = () => {
    setImprese([...imprese, { tipo: '', nome: '' }]);
  };

  const handleChangeImpresa = (index, e) => 
  {
    const { name, value } = e.target;
    setImprese((prevImprese) =>
      prevImprese.map((impresa, i) =>
        i === index ? { ...impresa, [name]: value } : impresa
      )
    );
  };

  const handleRemoveImpresa = (index) => {
    const updatedImprese = [...imprese];
    updatedImprese.splice(index, 1);
    setImprese(updatedImprese);
  };

 
  
 
  const handleEmailRLChange = (event) => {
    setEmailRL(event.target.value);
  }
  const handleNomeRLChange = (event) => {
    if (event.target) {
      setNomeRL(event.target.value);
    }
  }
  const handleCognomeRLChange = (event) => {
    if (event.target) {
      setCognomeRL(event.target.value);
    }
  }
  const handleTelefonoRLChange = (event) => {
    if (event.target) {
      setTelefonoRL(event.target.value);
    }
  }



  const handleIndirizzoChange = (event) => {
    setIndirizzo(event.target.value);
  };

  const handleDescrizioneChange = (event) => {
    setDescrizione(event.target.value);
  };

  const handleFileChange = (event) => {
    setDocumenti([...documenti, event.target.files[0]]);
  };

  const creaCantiere = async () => {
    try {
      const url = '/creaCantiere';
  
      const data = {
        descrizione,
        nome,
        cluster,
        indirizzo,
        documenti,
        importolavori,
        ODA,
        dataInizioLavori,
        dataFineLavori,
        site_ID,
      };
  
      const response = await axios.post(url, data);
  
      if (response.status === 201) {
        const cantiereId = response.data.idCantiere; // Assicurati che il tuo backend restituisca l'ID del cantiere creato
  
        console.log("cantiere ID",cantiereId);

        if (imprese && imprese.length > 0) {
          for (const impresa of imprese) {
            if (impresa) {
              // Crea i dati dell'impresa
              const dataImpresa = {
                tipoImpresa: impresa.tipoImpresa,
                ragioneSociale: impresa.ragioneSociale,
                indirizzoSedeLegale: impresa.indirizzoSedeLegale,
                telefonoUfficio: impresa.telefonoUfficio,
                email: impresa.emailImpresa,
                indirizzoPostaCertificata: impresa.indirizzoPostaCertificata,
              };
            
              console.log("dati dell'impresa", dataImpresa);
              // Invio dati dell'impresa al backend per la creazione
              const impresaResponse = await axios.post('/creaImpresa', dataImpresa);
  
              if (impresaResponse.status === 201) {
                const impresaId = impresaResponse.data.id; // Assicurati che il tuo backend restituisca l'ID dell'impresa creata
  
                // Ora associa l'impresa al cantiere
                await axios.post('/associaCantiereImpresa', {
                  IdCantiere: cantiereId,
                  IdImpresa: impresaId,
                  ruolo: impresa.ruolo || 'Non specificato', // Aggiungi il ruolo, se presente
                });
              } else {
                console.error(`Errore nella creazione dell'impresa ${impresa.ragioneSociale}:`, impresaResponse.statusText);
              }
            } else {
              console.error(`Impossibile creare il cantiere: impresa non valida`);
            }
          }
        }
  
        setSnackbarMessage('Cantiere creato con successo!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage('Errore nella creazione del cantiere.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error(`Impossibile creare il cantiere: ${error.message}`);
      setSnackbarMessage('Errore nella creazione del cantiere.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };





  const getOda = (event) => {
    setODA(event.target.value);
  };

  const getImportoLavori = (event) => {
    const value = event.target.value.replace(/[^0-9,.]/g, ''); 
    setImportoLavori(value);
  };

  const getStartDate = (date) => {
    setStartDate(date);
  };  

  const handleDataInizioLavori = (newdata) => {
     if(newdata)
     {
      //console.log(newdata);
     // const formattedDate = format(newdata, 'dd/MM/yyyy');
      setDataInizioLavori(newdata);
     }
    
  };
  const handleDataFineLavori = (newdata) => {
    
    if(newdata)
    {
     
      setDataFineLavori(newdata);
      
    }
  };

  const handleCalculateDays = () => {
    if (startDate && endDate) {
      const days = Math.ceil((new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24));
      alert(`I giorni tra le due date sono: ${days}`);
    } else {
      alert('Inserisci entrambe le date per calcolare i giorni.');
    }
  };

  const handleBlur = () => {
    // Applica la formattazione alla perdita di focus
    setImportoLavori(formatCurrency(importolavori));
  };

  const handleFocus = () => {
    // Rimuove la formattazione al focus per consentire l’editing
    setImportoLavori(importolavori.replace(/[^0-9.,]/g, ''));
  };

  useEffect(() => {
      const notifications = getNotifications();
      notifications.forEach((notification) => {
      setIndirizzo(notification.message.selectedSito);
      setCluster(notification.message.selectedCluster);
    });
  
    setCluster(sito.Cluster);
    
    setIndirizzo(sito.indirizzo);
    
    setNome(sito.citta);
    
    setIsBarraOpen(false);

  }, []);

  return (
    <div  style={{ backgroundColor: '#39414C'}}>
     

    
    <Box p={3}>
      <Box 
      mb={3} 
      p={2}
      bgcolor="#e0f7fa"
      borderRadius={4}
      border={1} // Aggiunge il bordo
      borderColor="black" // Colore del bordo
      >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Cluster"
            variant="outlined"
            fullWidth
            value={cluster}
            required
            InputProps={{
              style: {
                color: 'black', // Colore del testo
                backgroundColor: 'white', // Colore dello sfondo
              }
            }}
          />
        </Grid>
      
        <Grid item xs={12} sm={4}>
          <TextField
            label="Nome del Cantiere"
            variant="outlined"
            fullWidth
            value={nome}
            required
            InputProps={{
              style: {
                color: 'black', // Colore del testo
                backgroundColor: 'white', // Colore dello sfondo
              }
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            label="Indirizzo del Cantiere"
            variant="outlined"
            fullWidth
            value={indirizzo}
            required
            InputProps={{
              style: {
                color: 'black', // Colore del testo
                backgroundColor: 'white', // Colore dello sfondo
              }
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Descrizione"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={descrizione}
            onChange={handleDescrizioneChange}
            required
            InputProps={{
              style: {
                color: 'black', // Colore del testo
                backgroundColor: 'white', // Colore dello sfondo
              }
            }}
          />
        </Grid>


  
        <Grid item xs={12} sm={6}>
          <TextField
            label="Ordine di Acquisto"
            variant="outlined"
            fullWidth
            value={ODA}
            onChange={getOda}
            InputProps={{
              style: {
                color: 'black', // Colore del testo
                backgroundColor: 'white', // Colore dello sfondo
               
              }
            }}
            sx={{ width: '100%' }} // Assicurati che la larghezza sia 100%
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="Importo dei Lavori"
            variant="outlined"
            fullWidth
            type="text"       
            value={importolavori}
            onChange={getImportoLavori}
            onBlur={handleBlur}
            onFocus={handleFocus}
            InputProps={{
              style: {
                color: 'black', // Colore del testo
                backgroundColor: 'white', // Colore dello sfondo
                
              }
              
            }}
            sx={{ width: '100%' }} // Assicurati che la larghezza sia 100%
          />
        </Grid>
        
        
        <Grid item xs={12} sm={6}>
              
              <DatePicker
                selected={dataInizioLavori}
                onChange={handleDataInizioLavori}
               
                locale="it"
                inputFormat="dd/MM/yyyy"
                dateFormat="dd/MM/yyyy" // Formato della data in italiano
                placeholderText="Seleziona la data"
                customInput={
                  <TextField
                    label="Data Inizio Lavori"
                    fullWidth
                    required
                    InputProps={{
                      style: {
                        color: 'black',
                        backgroundColor: 'white',
                      },
                    }}
                  />
                }
              />
              
        </Grid>

        <Grid item xs={12} sm={6}>
        <DatePicker
                selected={dataFineLavori}
                onChange={handleDataFineLavori}
               
                locale="it"
                inputFormat="dd/MM/yyyy"
                dateFormat="dd/MM/yyyy" // Formato della data in italiano
                placeholderText="Seleziona la data"
                customInput={
                  <TextField
                    label="Data Fine Lavori"
                    outlined
                    fullWidth
                    required
                    InputProps={{
                      style: {
                        color: 'black',
                        backgroundColor: 'white',
                      },
                    }}
                  />
                }
              />
             
         </Grid>

        </Grid>
        </Box>
       


       {/*Sezione Responsabile dei lavori */}
        
       <Grid item xs={12}>
 
</Grid>

<Box
 mb={3}
  p={2}
   label="Responsabile dei lavori"
   variant="outlined"
   bgcolor="#ffe0b2" 
   borderRadius={4}
   border={1} // Aggiunge il bordo
   borderColor="black" // Colore del bordo
   >
    <Grid 
    container 
    justifyContent="center" 
    alignItems="center"
    mb={3}
    >
    <Grid item xs={12} sm={6}>
  <Typography variant="h6" align='center'>Responsabile dei Lavori:</Typography>
  </Grid>
    </Grid>
  <Grid container spacing={3}>

    <Grid item xs={12} sm={6}>
      <TextField
        label="Nome"
        variant="outlined"
        value={nomeRL}
        onChange={handleNomeRLChange}
        fullWidth
        InputProps={{
          style: {
            color: 'black', // Colore del testo
            backgroundColor: 'white', // Colore dello sfondo
          }
        }}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        label="Cognome"
        variant="outlined"
        fullWidth
        value={cognomeRL}
        onChange={handleCognomeRLChange}
        InputProps={{
          style: {
            color: 'black', // Colore del testo
            backgroundColor: 'white', // Colore dello sfondo
          }
        }}
      />
    </Grid>

    {/* Seconda riga: Email e Telefono */}
   
    <Grid item xs={12} sm={6}>
   
      <TextField
        label="Email"
        variant="outlined"
        fullWidth
        value={emailRL}
        onChange={handleEmailRLChange}
        InputProps={{
          style: {
            color: 'black', // Colore del testo
            backgroundColor: 'white', // Colore dello sfondo
          }
        }}
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <TextField
        label="Telefono"
        variant="outlined"
        fullWidth
        value={telefonoRL}
        onChange={handleTelefonoRLChange}
        InputProps={{
          style: {
            color: 'black', // Colore del testo
            backgroundColor: 'white', // Colore dello sfondo
          }
        }}
      />
    </Grid>
  </Grid>
</Box>

     
      <Box mb={3} 
      p={2} 
      bgcolor="#f1e0b2" borderRadius={4}
      border={1} // Aggiunge il bordo
      borderColor="black" // Colore del bordo
      >
       <Typography variant="h6" gutterBottom>Imprese</Typography>
        <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button variant="contained" onClick={handleAddImpresa}>
            Aggiungi Impresa
          </Button>
        </Grid>

        {imprese.map((impresa, index) => (
          <Grid item xs={12} key={index}>
            <Impresa
              
             state={state} 
             dispatch={dispatch}
             name="nome"
             value={impresa.nome}

              onChange={(e) => handleChangeImpresa(index, e)}
              onRemove={() => handleRemoveImpresa(index)}
            />
            <IconButton onClick={() => handleRemoveImpresa(index)}>
              <DeleteIcon />
            </IconButton>
            <Divider sx={{ 
              borderColor: 'black',
              borderWidth:3,
              borderStyle:'solid',
             }} />
          </Grid>
          


        ))}
        
        </Grid>
        </Box>
        
        <Box>
     </Box>
     <Button variant="contained" color="primary" onClick={creaCantiere} 
      
      sx={{
        borderRadius:'15px',
        '&:hover': {
          backgroundColor: 'darkblue', // Cambia colore di sfondo quando passi con il mouse
          color: 'white', // Cambia il colore del testo al passaggio
        },
             
      }}
      
     >
            Salva Cantiere
          </Button>


    </Box>
   
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
      
      >
      <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
       {snackbarMessage}
      </Alert>
    </Snackbar>

    </div>

  
  );
 
}
export default CreaCantiere;