import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  List,
  ListItem,
  ListItemText,
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Tooltip,
  LinearProgress,
  Box,
} from '@mui/material';
import Barra from './Barra';
import FiltroBar from './FiltroBar';
import { AddCircleOutline } from '@mui/icons-material';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import './ListaSiti.css';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip as ChartTooltip, Legend, CategoryScale, registerables } from 'chart.js';
import { useNavigate } from 'react-router-dom';
import MapComponent from './MapComponent';

Chart.register(...registerables);
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// Funzione per gestire la barra di avanzamento del cantiere
  const CantiereProgressBar = ({ progresso }) => {
  const valoreProgresso = progresso ||0;
  const coloreBarra = valoreProgresso < 50 ? 'orange' : 'green';

  return (
    <Tooltip title={`${valoreProgresso}%`}arrow>
      <div style={{width: '100%',marginTop:'5px'}}>
      <LinearProgress
        variant="determinate"
        value={progresso}
        sx={{
          height: 10,
          borderRadius: 5,
          '& .MuiLinearProgress-bar': {
            backgroundColor: coloreBarra,
          },
        }}
      />
      </div>
    </Tooltip>
  );
};

const CantiereProgressBar1 = ({ progresso }) => {
  const valoreProgresso = progresso ;
  const coloreBarra = valoreProgresso < 50 ? 'orange' : 'green';

  return (
    <Tooltip title={`${valoreProgresso}%`}>
       <div style={{width: '100%',marginTop:'5px'}}>
      <LinearProgress
        variant="determinate"
        value={progresso}
        sx={{
          height: 10,
          borderRadius: 5,
          '& .MuiLinearProgress-bar': {
            backgroundColor: coloreBarra,
          },
        }}
      />
      </div>
    </Tooltip>
  );
};




// Componente SitiChart per visualizzare il grafico a ciambella
const SitiChart = ({ numeroCantieri, completamentoMedio }) => {
  const data = {
    labels: ['Cantieri Completati', 'Cantieri da Completare'],
    datasets: [
      {
        label: 'Completamento Cantieri',
        data: [completamentoMedio, 100 - completamentoMedio],
        backgroundColor: ['#4caf50', '#e0e0e0'],
        hoverBackgroundColor: ['#66bb6a', '#e0e0e0'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || '';
            const value = context.raw;
            return `${label}: ${value}`;
          },
        },
      },
    },
  };

  return (
    <div style={{ width: '100%', height: '100px' }}>
      <Doughnut data={data} options={options} />
    </div>
  );
};











const ListaSiti = () => {
  const [sites, setSites] = useState([]);
  const [filteredSites, setFilteredSites] = useState([]);
  const [isBarraOpen, setIsBarraOpen] = useState(false);
  const [selectedSite, setSelectedSite] = useState(null);
  
const navigate = useNavigate();
  const cantieriFittizi = [
    {
      id: 1,
      nome: "Costruzione edificio A",
      progresso: 65,
      ProgressoEconomico:10,
      inizioLavori: "2023-01-01",
      fineLavoriPrevista: "2023-12-31",
      ubicazione: "Roma",
      cliente: "XYZ Costruzioni",
      note: "Ritardi previsti a causa delle avverse condizioni meteo"
    },
    {
      id: 2,
      nome: "Ristrutturazione villa storica",
      progresso: 90,
      progressoEconomico:95,
      inizioLavori: "2022-05-15",
      fineLavoriPrevista: "2023-04-30",
      ubicazione: "Firenze",
      cliente: "Associazione culturale"
    },
    {
      id: 3,
      nome: "Costruzione complesso residenziale",
      progresso: 25,
      progressoEconomico:5,
      inizioLavori: "2023-03-10",
      fineLavoriPrevista: "2024-06-30",
      ubicazione: "Milano",
      cliente: "Immobiliare Alfa"
    },
    // ... altri cantieri
  ];
  // Funzione per ottenere i siti
  const fetchSites = async () => {
    try {
      const response = await axios.get('/recuperaSiti');
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  // Effetto per caricare i siti al primo montaggio del componente
  useEffect(() => {
    const loadSites = async () => {
      const newSiti = await fetchSites();
      if (newSiti) {
        const sitiConCantieri = newSiti.map((site) => ({
          ...site,
          cantieri: cantieriFittizi, // Assegna tutti i cantieri fittizi a ogni sito
        }));

        setSites(sitiConCantieri);
        setFilteredSites(sitiConCantieri);
      }
    };

    loadSites();
  }, []);

  // Funzione per applicare i filtri
  const applyFilters = (filters) => {
   // console.log('Applico i filtri', filters);
    const { nome, nazione, cluster, progressoMin, progressoMax, projectManager } = filters;
   // console.log(sites);
    const filtered = sites.filter((site) => {
        // Filtro per il nome
        const nameFilter = !nome || (site.city && site.city.toLowerCase().includes(nome.toLowerCase()));

        // Filtro per la nazione
        const nationFilter = !nazione || (site.nation && site.nation === nazione);

        // Filtro per il cluster
        const clusterFilter = !cluster || (site.Cluster && site.Cluster === cluster);

        // Filtro per il progresso
        const progressFilter = (
            (!progressoMin && !progressoMax) ||
            (site.workshops && site.workshops.every((workshop) => {
                const workshopProgress = workshop.progress || 0; // Assicurati che il progresso esista
                return (
                    (progressoMin === '' || workshopProgress >= progressoMin) &&
                    (progressoMax === '' || workshopProgress <= progressoMax)
                );
            }))
        );

        // Filtro per il project manager
        const projectManagerFilter = !projectManager ||
        (site.projectManagerNome && site.projectManagerNome.toLowerCase().includes(projectManager.toLowerCase())) ||
        (site.projectManagerCognome && site.projectManagerCognome.toLowerCase().includes(projectManager.toLowerCase()));

        // Restituisci true se tutti i filtri sono soddisfatti
        return nameFilter && nationFilter && clusterFilter && progressFilter && projectManagerFilter;
    });

    setFilteredSites(filtered);
};


  const creaNuovoSito = () => {
    navigate("/creaNuovoSito");
  };

  //gestione click su card


  const handleCardClick = (site) => {
      setSelectedSite(site);
    //console.log("click su card");
   // console.log('Navigating to site details with site:', site); // 
    if (site) {
      navigate("/sito/${site.id}", { state: { site } });
    }
  }



  return (
    <div className="prova"
     
    style={{
      height: '100vh', 
    }}
    
    >
      <Barra isBarraOpen={isBarraOpen} toggleBarra={() => setIsBarraOpen(!isBarraOpen)} />
      <AppBar position="static">
        <Toolbar variant="dense" sx={{ backgroundColor: '#A0C646' }}>
          <Typography variant="h6" noWrap component="div" sx={{ mr: 2 }}>
            Gestione Siti
          </Typography>
          <Button color="primary" variant="contained" onClick={creaNuovoSito} sx={{ ml: 2 }}>
            Crea Nuovo Sito
          </Button>
          <Button color="primary" variant="contained" sx={{ ml: 'auto' }} onClick={() => setIsBarraOpen(true)}>
            <AddCircleOutline />
          </Button>
        </Toolbar>
      </AppBar>

      <FiltroBar onFilterChange={applyFilters} />
      <Box sx={{ maxHeight:500, overflowY: 'auto' }}>
      <Grid container spacing={3}>
        {filteredSites.map((site) => {
          const numeroCantieri = site?.cantieri?.length || 0;
          const completamentoMedio = site?.cantieri
            ? site.cantieri.reduce((acc, cantiere) => acc + (cantiere?.progresso || 0), 0) / numeroCantieri
            : 0;

          return (
            <Grid key={site?.ID} item xs={12} sm={6} md={6}>
              <Card sx={{ border: site.cantieri?.some((cantiere) => cantiere.progresso < 50) ? '2px solid red' : '' }}>
             
              <MapComponent lat={site?.LAT} lng={site?.LON} />
                <CardContent>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                    <Typography variant="h8">{site?.nome} ({site?.Cluster})</Typography>
                    <Typography variant="body1">{site?.nazione}</Typography>
                    <Typography variant="body1">{site?.citta}</Typography>
                    <Typography variant="body1">{site?.indirizzo}</Typography>
                    </div>
                    <div>
                      <Typography variant="body1" color="text.primary">
                        Project Manager: { 
                      
                       site?.projectManagerNome+" "+site?.projectManagerCognome
                       }
                      </Typography>
                      <Typography variant="body1" color="text.primary">
                        Tiket Aperti: {30}
                      </Typography>
                      <Typography variant="body1" color="text.primary">
                        Tiket Evasi: {121}
                      </Typography>
                      <Typography variant="body1" color="text.primary">
                      Cantieri Attivi: {3}
                      </Typography> 
                      <Typography variant="body1" color="text.primary">
                      Cantieri Completati: {21}
                      </Typography>                         
                      <Typography variant="body1" color="text.primary">
                      Cantieri Totali: {24}
                      </Typography>                   
                    </div>
                  </div>
                  <SitiChart numeroCantieri={numeroCantieri} completamentoMedio={completamentoMedio} />
                  <List dense>
                    {site.cantieri?.map((cantiere) => (
                      <ListItem key={cantiere?.id} sx={{ display:'flex',alignItems:'center',padding: '10px 0',borderBottom: '1px solid #ccc' }}>
                        <ListItemText 
                        primary={cantiere?.nome}
                        primaryTypographyProps={{
                          style:{color:'black'},
                          variant:'body1'}}
                          sx ={{marginRight:'10px',width:'150px'}}

                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch',marginRight: '10px',height:'auto',width:'100%' }}>
                        <CantiereProgressBar progresso={50} />
                        <CantiereProgressBar1 progresso={50} />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <ConfirmationNumberIcon color="error" sx={{ marginRight: '5px' }} />
                          <Typography variant="body2">10</Typography>
                        </Box>
                    
                      </ListItem>
                       
                    ))}
                  </List>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                         variant="contained" 
                         color="success" 
                         sx={{ marginRight: '5px' }}
                         onClick={()=>handleCardClick(site)}
                         >
                          Dettagli
                        </Button>
                       
                      </Box>
                </CardContent>
              </Card>
            </Grid>
           
          );
        })}
      </Grid>
      </Box>
    </div>
  );
};

export default ListaSiti;
