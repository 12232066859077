import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './Map.css';
import CantiereSidebar from './cantiereSiderbar';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {Col,Row} from 'react-bootstrap'
axios.defaults.baseURL = process.env.REACT_APP_API_URL;


// Icona di default per i markMer

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const updateSiteCoordinates = async (siteId, lat, lon) => {

   console.log("update database");
  try {
      // Effettua una richiesta PUT al server per aggiornare le coordinate del sito
      const response = await axios.post(`/updateSiteCoordinates`, { lat, lon,siteId});
      // Controlla la risposta dal server e gestisci eventuali errori o log di conferma
      console.log("Coordinate del sito aggiornate con successo:", response.data);
  } catch (error) {
      console.error('Errore durante l\'aggiornamento delle coordinate del sito:', error);
      // Gestisci eventuali errori o notifiche di errore al chiamante
  }
};

const fetchSites = async () => {
  try {
      
      //costruisce il path del database
      const url1 =  '/recuperaSiti'
      //const url = new URL(url1, 'https://demosgs.it');
      //prende dal database i siti con gli indirizzi indirizzi 
      const response = await axios.get(url1);

      const sitesWithCoordinates = await Promise.all(response.data.map(async (site) => 
      {
        
      //codifica la url 
      const address = encodeURIComponent(`${site.nazione}, ${site.citta}, ${site.indirizzo}`);
      //prende dal database i siti
      if(site.LAT!==null && site.LON!==null)
      {
        return { ...site, lat: site.LAT, lon: site.LON };
      } 
      else
      {
      const geocodingResponse = await axios.get(`/geocode?address=${address}`);
      
      
      if (geocodingResponse.data && geocodingResponse.data.length > 0) {
        const { lat, lon } = geocodingResponse.data[0];
        if (lat !== undefined && lon !== undefined)
         {
        const risposte = await updateSiteCoordinates(site.ID, lat, lon);
        return { ...site, lat, lon };
         }
         else
         {
          console.error('Geocoding fallito: valori lat o lon mancanti per l\'indirizzo:', address);
          return null;
         }
      } 
      else 
      {
        console.error('Geocoding fallito per l\'indirizzo:', address);
        return null;
      }
     }
  }));
   
    return sitesWithCoordinates.filter(site => site !== null);
    
  } 
  catch (error) {
  console.error('Errore durante il recupero dei siti:', error);
  return [];
  }
};




const Map = () => {

  const [sites, setSites] = useState([]);

  useEffect(() => 
  {
    fetchSites().then(siteson => {
    
      if (siteson.length === 0) {
        console.error('Nessun sito trovato');
        
      } else 
      {
        setSites([...sites,...siteson]);
        console.log('Siti caricati:', siteson);
      }
    });
  }, []);


  return (
   <div>
  <Row>
  <MapContainer center={[41.8719, 12.5674]} zoom={6} style={{ height: '100vh', width: '100%' }}>
    <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
  
  {sites.map((site) => {
   

    return (
      <Marker key={site.id} position={[site.lat, site.lon]}>
        <Popup>
          {site.cluster},{site.nazione}, {site.citta}, {site.indirizzo}
        </Popup>
      </Marker>
    );
})}
</MapContainer>
</Row>
</div>

);
};

export default Map;