import React, { createContext, useContext, useState } from 'react';

// Creazione del contesto
const NotificationContext = createContext();

// Fornitore di contesto
export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = (notification) => {
    setNotifications((prev) => [...prev, notification]);
  };

  const getNotifications = () => {
    const currentNotifications = [...notifications];
    setNotifications([]); // Cancella le notifiche dopo il recupero
    return currentNotifications;
  };

  return (
    <NotificationContext.Provider value={{ addNotification, getNotifications }}>
      {children}
    </NotificationContext.Provider>
  );
};

// Hook per utilizzare il contesto
export const useNotifications = () => {
  return useContext(NotificationContext);
};