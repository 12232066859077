import React, { useState,useEffect } from 'react';
import './App.css';
import { UserProvider } from './modules/UserContext'; // Importazione corrett
import{CantiereProvider} from './modules/CantiereContext'
import Login from './modules/Login';
import Register from './modules/Register';
import Ispezione from './modules/Ispezione';
import Footer from './modules/Footer';
import CreaNuovoSito from './modules/creaNuovoSito';
import CantiereProva from './modules/CantiereProva';
import { BrowserRouter as Router, Routes, Route,Link,useLocation } from 'react-router-dom';
import VerbaleVerifica from './modules/VerbaleVerifica';
import Home from './HOME/Home';
import ChiSiamo from './modules/ChiSiamo';
import TeamPage from './modules/TeamPage';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ListaUtenti from './modules/ListaUtenti';
import ListaSiti from './modules/ListaSiti';
import NavigationBar from './modules/NavigationBar'
import DashBoard from './modules/DashBoard';
import Agenda from './modules/Agenda';
import { SnackbarProvider } from 'notistack';
import TaskManager from './modules/TaskManager';
import InformazioniEsteseSito from './modules/InformazioniEsteseSito';
import GestioneCantiere from './modules/GestioneCantiere';


function BreadcrumbNavigation() {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  // Mappa dei nomi dei breadcrumb
  const breadcrumbNameMap = {
    DashBoard: "Dashboard",
    "Lista Siti": "Lista Siti",
    "creaNuovoSito": "Crea Nuovo Sito",
    creaSito: "Crea Sito",
    Home: "Home",
    "Chi Siamo": "Chi Siamo",
    "Il Team": "Il Team",
    sito: "Dettagli Sito",
    cantiere: "Dettagli Cantiere",
    Agenda: "Agenda",
    gestioneCantiere: "Gestione Cantiere",
    creaCantiere: "Crea Cantiere"
  };
  const displayedPathnames = pathnames.slice(0, 3);
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">DashBoard</Link>
        </li>
        {displayedPathnames.map((value, index) => {
          // Costruisce il percorso cumulativo per ogni segmento
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;
          const breadcrumbName = breadcrumbNameMap[value] || value;

          return isLast ? (
            <li key={to} className="breadcrumb-item active" aria-current="page">
              {breadcrumbName}
            </li>
          ) : (
            <li key={to} className="breadcrumb-item">
              <Link to={to}>{breadcrumbName}</Link>
            </li>
          );
        })}
      </ol>
    </nav>
  );
}



function App() {
  const [isAuthenticated, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const handleLoginClick = () => {
    setShowLogin(true);
    console.log("mi stai premendo");
  };

  const handleSignInClick = () => {
    setShowRegister(true);
    console.log("mi stai premendo");
  };

  const hideRegisterForm = () => {
    setShowRegister(false);
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://consent.cookiebot.com/uc.js';
    script.setAttribute('data-cbid', 'b1bce43-5a1a-4d3e-9754-1667dcad6687'); // Sostituisci con il tuo ID
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Rimuovi lo script quando il componente viene smontato
    };
  }, []);
  
  return (
    <CantiereProvider>
    <UserProvider>
      <SnackbarProvider maxSnack={3}> 
    <Router>
      <div style={{ display: 'block' ,minHeight: '100vh'}}>
      
       <NavigationBar />
       <div style={{ flex: 1, padding: '1px' }}>
       
        <div style={{ flex: 1, padding: '1px' }}>
        <BreadcrumbNavigation />
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/Home" element={<Home/>}/>
            <Route path="/Chi Siamo" element={<ChiSiamo />}/>
            <Route path="/Il Team" element={<TeamPage/>}/>
            <Route path="/login" element={<Login />} />
            <Route path="/Register" element={<Register />} />


            <Route path = "/Dashboard" element={<DashBoard /> }/>
            <Route path="/creaSito" element={<CreaNuovoSito />} /> 
            <Route path="/creaCantiere" element={<CantiereProva />}/>
            <Route path="/ispezione" element={<Ispezione />} isAuthenticated={isAuthenticated}/>
            <Route path="/verifica" element={<VerbaleVerifica />} isAuthenticated={isAuthenticated}/>
            <Route path="/Utenti"  element={<ListaUtenti />} isAuthenticated={isAuthenticated}/>
            <Route path="/Lista Siti" element={<ListaSiti/>} isAuthenticated={isAuthenticated}/>
            <Route path="/Agenda" element={<Agenda/>} isAuthenticated={isAuthenticated}/>
            <Route path="/creaNuovoSito" element={<CreaNuovoSito />} isAuthenticated={isAuthenticated} />
            <Route path="/sito/:id" element={<InformazioniEsteseSito/>} />
            <Route path="/cantiere/:id" element={<InformazioniEsteseSito/>} />
            <Route path="/TaskManager" element={<TaskManager/>}/>
            <Route path="/gestioneCantiere" element={<GestioneCantiere/>} />
          </Routes>
          
        </div>
        <Footer/>
      </div>
      </div>
    </Router>
    </SnackbarProvider>
    </UserProvider>
    </CantiereProvider>
  );
}

export default App;
